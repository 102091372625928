import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const AdminContactDetails = () => {

   // Ck Editor Code

  const [Desc, setDesc] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


  //Frontend Integration

  const [ContactEmail, setContactEmail] = useState("");
  const [ContactNo, setContactNo] = useState("");

  //POST

  const createContactDetails = async () => {
    try {
      const config = {
        url: "/createcontactdetails",
        method: "post",
        baseURL: "https://theuniversityoftrinity.com/api/contact",
        headers: { "content-type": "application/json" },
        data: {
          contactno: ContactNo,       
          contactemail: ContactEmail,
          contactaddress: Desc,
        },
      };

      let res = await axios(config);
      console.log("res",res)
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getContactDetails();
        setContactEmail("");
        setContactNo("");
        setDesc("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //GET

  const [ContactDetails, setContactDetails] = useState([]);

  console.log(ContactDetails,":ContactDetails")

  const getContactDetails = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/contact/getcontactdetails");
      if (res.status === 200) {
        setContactDetails(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

  //UPDATE
  
  const [ContactDetailsId, setContactDetailsId] = useState({})

  const editContactDetails = async()=>{

    try {
    const config = {
      url: "/updatecontactdetails/"+ContactDetailsId?._id,
      method: "put",
      baseURL: "https://theuniversityoftrinity.com/api/contact",
      headers: { "content-type": "application/json" },
      data: {
        contactno: ContactNo,       
        contactemail: ContactEmail,
        contactaddress: Desc,
      },
    };
    let res = await axios(config);
    if (res.status === 201) {
      alert(res.data.status);
      handleClose4()
      getContactDetails();
      setContactEmail("");
        setContactNo("");
        setDesc("");
    }
  }
   catch (error) {
    alert(error.response.data.error);
  }
  }

  //DELETE

  const deleteContactDetails = async () => {
    try {
      let res = await axios.delete(`https://theuniversityoftrinity.com/api/contact/deletecontactdetails/${ContactDetailsId?._id}`);
      if (res.status === 201) {
        alert(res.data.status);
        getContactDetails();
        handleClose5()
      }
    } catch (error) {
      alert(error.response.data.error)
    }
  };

  useEffect(() => {
    getContactDetails();
  }, []);
 

  return (
    
    <div>

    <div className="customerhead p-2">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="header-c ">Contact Details</h2>
              <div className="d-flex gap-3">
              {ContactDetails?.length ? (""):(
                <button className="admin-add-btn" onClick={handleShow3}>
                  Add 
                </button>
               )}
              </div>
            </div>
    
            <div className="mb-3">
              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
    
                  <tr>
                    <th>Sl.No</th>
                    <th>Address</th>   
                     <th>Contact No</th>  
                     <th>Email</th>               
                    <th>Action</th>
                  </tr>
    
                </thead>
    
                <tbody>
                 
                {ContactDetails?.map((item, i) => {
                    return (
                 
                      <tr>
                        <td>{i+1}</td>
                        
                        <td style={{ paddingTop: "10px" }}>
                          {parse(item?.contactaddress || "")}
                        </td>

                        <td style={{ paddingTop: "10px" }}>{item?.contactno}</td>
                        <td style={{ paddingTop: "10px" }}>{item?.contactemail}</td>

                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className=""
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                
                                }}
                                onClick={() =>{
                                  handleShow4();
                                 setContactDetailsId(item);
                                }
                                   
                                  }
                              />{" "}
                            </div>

                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  
                                }}
                                onClick={() => {
                                  handleShow5();
    
                                  setContactDetailsId(item);
    
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
    
                     );
                  })}
                 
                </tbody>
              </Table>
            </div>
    
            {/* Add Package modal for Slider */}
    
            <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
              <Modal.Header closeButton>
                <Modal.Title>Add</Modal.Title>
              </Modal.Header>
              <Modal.Body>

               
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Address</label>               
                    <CKEditor editor={ClassicEditor} onChange={handleChange} />
                  </div>
                </div>

                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Contact Number</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Contact Number"
                       onChange={(e) => setContactNo(e.target.value)}
                    />
                  </div>
                </div>

                
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Email</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Email"
                       onChange={(e) => setContactEmail(e.target.value)}
                    />
                  </div>
                </div>


              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <Button
                    className="mx-2 modal-close-btn"
                    variant=""
                    onClick={handleClose3}
                  >
                    Close
                  </Button>
                  <Button
                    className="mx-2 modal-add-btn"
                    variant=""
                    onClick={createContactDetails}
                  >
                    Add
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
    
            {/* Edit Package modal for Slider */}
    
            <Modal
              show={show4}
              onHide={handleClose4}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  Edit Contact Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                
              <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Contact Address</label>
                   
                    <CKEditor editor={ClassicEditor} onChange={handleChange} />
                  </div>
                </div>
    
              <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Contact Number</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Contact Number"
                       onChange={(e) => setContactNo(e.target.value)}
                    />
                  </div>
                </div>

               
              <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Contact Email</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Email "
                      onChange={(e) => setContactEmail(e.target.value)}
                    />
                  </div>
                </div>
               
               
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose4}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                 onClick={editContactDetails}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
    

        {/*Delete Package modal for Slider */}
    
            <Modal
              show={show5}
              onHide={handleClose5}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >

              <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-4" style={{ color: "red" }}>
                      Are you sure?
                      <br/> you want to delete this data?
                    </p>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose5}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                   onClick={deleteContactDetails}
                >
                  Delete
                </Button>
              </Modal.Footer>

            </Modal>
          </div>
    
     </div>

  )
}

export default AdminContactDetails