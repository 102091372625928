import React from 'react'
import { BrowserRouter as Router ,Routes,Route } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'; //bootstrap

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Header from './Components/Header';
import Home from './Components/Home';
import About from "./Components/About";
import Gallery from "./Components/Gallery";
import Contact from "./Components/Contact"
import Service from './Components/Service';
import Footer from './Components/Footer';

import Whatsapp from "./Components/Whatsapp";

 import Login from './Components/Login';
 import SignUp from './Components/SignUp';

import Main from "./Components/Admin/Main";
import AdminSlider from './Components/Admin/AdminSlider'
import AdminHomeFeature from './Components/Admin/AdminHomeFeature';
import AdminHomeCourse from './Components/Admin/AdminHomeCourse';
import AdminHomeVideo from './Components/Admin/AdminHomeVideo';
import AdminHomeEvent from './Components/Admin/AdminHomeEvent';
import AdminHomeTestimonial from './Components/Admin/AdminHomeTestimonial';
import AdminAboutDescription from './Components/Admin/AdminAboutDescription';
import AdminAboutVision from './Components/Admin/AdminAboutVision';
import AdminAboutOurteam from './Components/Admin/AdminAboutOurteam';
import AdminAboutManagementTeam from './Components/Admin/AdminAboutManagementTeam';
import AdminServiceCategory from './Components/Admin/AdminServiceCategory';
import AdminServiceCategoryContent from './Components/Admin/AdminServiceCategoryContent';
import AdminGalleryContent from './Components/Admin/AdminGalleryContent';
import AdminContactDetails from './Components/Admin/AdminContactDetails';
import AdminContactReachusForm from './Components/Admin/AdminContactReachusForm';
import AdminContactDescription from './Components/Admin/AdminContactDescription';
import AdminContactFeedbackForm from './Components/Admin/AdminContactFeedbackForm';
import AdminSocialMedia from './Components/Admin/AdminSocialMedia';
import AdminServiceCourse from './Components/Admin/AdminServiceCourse';
import AdminAboutImage from './Components/Admin/AdminAboutImage';
import AdminGalleryVideo from './Components/Admin/AdminGalleryVideo';
import ScrollButton from './Components/ScrollButton';

function App() {
  return (
    
    <Router> {/* Make sure Router is consistently used */}

      <Routes>

        <Route path='/' element={
          <>
          <Header/>
          <Home />
          <Whatsapp/>
          <ScrollButton/>
          <Footer/>
          </>
          } />

        <Route path='/about' element={
          <>
          <Header/>
         <About/>
         <Whatsapp/>
         <ScrollButton/>
         <Footer/>
          </>
          } />

      <Route path='/service' element={
          <>
          <Header/>
         <Service/>
         <Whatsapp/>
         <ScrollButton/>
         <Footer/>
          </>
          } />

      <Route path='/gallery' element={
          <>
          <Header/>
          <Gallery/>
          <Whatsapp/>
          <ScrollButton/>
          <Footer/>
          </>
          } />

      <Route path='/contact' element={
          <>
          <Header/>
          <Contact/>
          <Whatsapp/>
          <ScrollButton/>
          <Footer/>
          </>
          } />


          {/* Login */}

         <Route path="/login" element={<>

          <Login />

          </>} />


        {/*Sign up */}

        <Route path="/signup" element={<>

        <SignUp />

        </>} />



    {/*============== DASHBOARD ===========*/}


      {/* HOME */}

     <Route
            path="/admin_slider"
            element={
              <Main
                children={
                  <>
                    <AdminSlider />
                  </>
                }
              />
            }
     />


    <Route
            path="/admin_homefeatures"
            element={
              <Main
                children={
                  <>
                    <AdminHomeFeature/>
                  </>
                }
              />
            }
     />


      <Route
            path="/admin_homecourse"
            element={
              <Main
                children={
                  <>
                   <AdminHomeCourse/>
                  </>
                }
              />
            }
     />


      <Route
            path="/admin_homevideo"
            element={
              <Main
                children={
                  <>
                   <AdminHomeVideo/>
                  </>
                }
              />
            }
     /> 

      <Route
            path="/admin_homeevents"
            element={
              <Main
                children={
                  <>
                   <AdminHomeEvent/>
                  </>
                }
              />
            }
     /> 


      <Route
            path="/admin_hometestimonials"
            element={
              <Main
                children={
                  <>
                    <AdminHomeTestimonial/>
                  </>
                }
              />
            }
     /> 

    {/* ABOUT */}

    <Route
            path="/admin_aboutimage"
            element={
              <Main
                children={
                  <>
                    <AdminAboutImage/>
                  </>
                }
              />
            }
     /> 

    <Route
            path="/admin_aboutdescription"
            element={
              <Main
                children={
                  <>
                   <AdminAboutDescription/>
                  </>
                }
              />
            }
     /> 


      <Route
            path="/admin_aboutvision"
            element={
              <Main
                children={
                  <>
                  <AdminAboutVision/>
                  </>
                }
              />
            }
      /> 


      <Route
            path="/admin_aboutourteam"
            element={
              <Main
                children={
                  <>
                    <AdminAboutOurteam/>
                  </>
                }
              />
            }
      /> 

      <Route
            path="/admin_aboutmanagementteam"
            element={
              <Main
                children={
                  <>
                    <AdminAboutManagementTeam/>
                  </>
                }
              />
            }
      /> 


    {/* SERVICE & COURSES */}

      <Route
            path="/admin_ourservices"
            element={
              <Main
                children={
                  <>
                    <AdminServiceCategoryContent/>
                  </>
                }
              />
            }
      /> 

    <Route
            path="/admin_servicecourse"
            element={
              <Main
                children={
                  <>
                    <AdminServiceCourse/>
                  </>
                }
              />
            }
     />

      {/* GALLERY */}

      <Route
            path="/admin_gallerycontents"
            element={
              <Main
                children={
                  <>
                    <AdminGalleryContent/>
                  </>
                }
              />
            }
      /> 


    <Route
            path="/admin_galleryvideo"
            element={
              <Main
                children={
                  <>
                    <AdminGalleryVideo/>
                  </>
                }
              />
            }
      /> 

      {/* CONTACT */}
      
      <Route
            path="/admin_contactdetails"
            element={
              <Main
                children={
                  <>
                  <AdminContactDetails/>
                  </>
                }
              />
            }
      /> 


      <Route
            path="/admin_contactreachusform"
            element={
              <Main
                children={
                  <>
                    <AdminContactReachusForm/>
                  </>
                }
              />
            }
      /> 


      <Route
            path="/admin_contactdescription"
            element={
              <Main
                children={
                  <>
                    <AdminContactDescription/>
                  </>
                }
              />
            }
      /> 


      <Route
            path="/admin_contactfeedbackform"
            element={
              <Main
                children={
                  <>
                   <AdminContactFeedbackForm/>
                  </>
                }
              />
            }
      /> 


      <Route
            path="/admin_socialmedia"
            element={
              <Main
                children={
                  <>
                    <AdminSocialMedia/>
                  </>
                }
              />
            }
      /> 

     </Routes>

   {/* <ScrollButton/> */}

  </Router>

  );
}

export default App;