import React, {useEffect, useState } from 'react';
import '../Styles/Contact.css'
import { Container } from 'react-bootstrap';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Aos from "aos";
import axios from 'axios';

import parse from 'html-react-parser';

const Contact = () => {

    useEffect(() => {
        Aos.init();
        window.scroll(0, 0);
      }, []);


    //Frontend Integration
    
    //GET (Contact Details)

  const [ContactDetails, setContactDetails] = useState([]);

  console.log(ContactDetails,":ContactDetails")

  const getContactDetails = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/contact/getcontactdetails");
      if (res.status === 200) {
        setContactDetails(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

  useEffect(() => {
    getContactDetails();
  }, []);

  
//POST (Contact Enquiry Form)

  const [Desc, setDesc] = useState()

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data)
  }

  const [Name, setName] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");

  
  const createReachusEnquiry = async () => {

    try {
      const config = {
        url: "/createcontactreachusenq",
        method: "post",
        baseURL: "https://theuniversityoftrinity.com/api/contact",
        headers: { "content-type": "application/json" },
        data: {
            enqname: Name,
            enqphoneno: Phone,
            enqemail: Email,
            enqphonemsg: Message
         
        },
      };

      console.log("config", config);

      let res = await axios(config);
      if (res.status === 200) {
        alert('Thank you for reaching us.')
        setName("");
        setPhone("");
        setEmail("");
        setMessage("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //POST (Feedback Form)

  const [Desc1, setDesc1] = useState()

  const handleChange1 = (e, editor) => {
    const data = editor.getData1();
    setDesc1(data)
  }

  const [FeedbackName, setFeedbackName] = useState("");
  const [FeedbackPhone, setFeedbackPhone] = useState("");
  const [FeedbackEmail, setFeedbackEmail] = useState("");
  const [FeedbackMessage, setFeedbackMessage] = useState("");

  const createFeedback = async () => {

    try {
      const config = {
        url: "/createcontactfeedback",
        method: "post",
        baseURL: "https://theuniversityoftrinity.com/api/contact",
        headers: { "content-type": "application/json" },
        data: {
            name: FeedbackName,
            email: FeedbackEmail,
            phoneno: FeedbackPhone,
            msg: FeedbackMessage,
            
        },
      };

      console.log("config", config);

      let res = await axios(config);
      if (res.status === 200) {
        alert('Thank you for reaching us..')
        setFeedbackName("");
        setFeedbackPhone("");
        setFeedbackEmail("");
        setFeedbackMessage("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
   
    <div className='contact-mainpage'>

    <section className='about-bannerimg'
                style={{
                    backgroundImage: `url("/Assets/banner.avif")`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "200px",
                    width: "100%"
                }}
            >
                <div className="container ">

                    <div className='breadcrumbt'>

                        <div>
                            <h1 className='commonbanner-heading'>Contact</h1>             
                        </div>

                        <div className='mbl-responsive-breadcrumbs'>
                            <Breadcrumb>
                                <FontAwesomeIcon icon={faHouse} style={{ fontSize: '14px', marginTop: '0px', color: "rgb(0,105,176)", padding:"5px" }} />
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active className='abtus-small-heading' style={{color:"white"}}>Contact</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        
                    </div>
                    
                </div>
    </section>


 <section>

        {/* <img src="../Assets/contactus1.png" alt="" className='setion-img' /> */}
       
        <Container >
            <div class='row mt-3 mb-3' data-aos="zoom-in" data-aos-duration="3000">                
                 
            {ContactDetails?.map((item, i) => {
                    return (
                <>

                <div class="col-md-4 mt-3" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="contact-bx" >
                        <img src="../Assets/locations.png" alt="Location" />
                        <h3 class="sect-title-3">Address</h3>
                        <p className='text-justify'>{parse(item?.contactaddress || "")}</p>
                    </div>
                </div>

                <div class="col-md-4 mt-3" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="contact-bx">
                        <img src="../Assets/contacticon.png" alt="Location" />

                        <h3 class="sect-title-3">Contact Number</h3>
                        <p className='text-left '>{item?.contactno}</p>
                        {/* <p  className='text-left mobile2'>+91- 9742175866</p> */}
                        {/* <p  className='text-left mobile2'>+91- 9731051944</p> */}
                    </div>
                </div>

                <div class="col-md-4 mt-3 mb-3" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="contact-bx">
                        <img src="../Assets/email.png" alt="Location"/>
                        <h3 class="sect-title-3">Email</h3>
                        <p>{item?.contactemail}</p>
                    </div>
                </div>

            </>
            );
            })}

            </div>
        </Container>
    </section> 


    {/* ReachUs Enquiry */}

     <Container>
        <div className='row' id='contct-img'>
            <div className='col-md-5 col-12' data-aos="zoom-in" data-aos-duration="3000">

                <form className='contact-form'>

                    <div className='form-bg'>
                        <h1 style={{ color: "white" }}>Reach-Us</h1>

                        <div className='row'>

                            <div className='col-12'>
                                <div className='single-form'>
                                    <input
                                     onChange={(e) => setName(e.target.value)}
                                     value={Name}
                                     type="text" className='form-control' placeholder='Name...' />
                                </div>
                            </div>

                            <div className='col-12'>
                                <div className='single-form'>
                                    <input
                                     value={Email}
                                     onChange={(e) => setEmail(e.target.value)}
                                     type="email" className='form-control' placeholder='Email...' />
                                </div>
                            </div>

                            <div className='col-12'>
                                <div className='single-form'>
                                    <input                                   
                                     value={Phone}
                                     onChange={(e) => setPhone(e.target.value)}
                                     type="tel" className='form-control' placeholder='Phone Number...' />
                                </div>
                            </div>

                
                            <div className='col-12'>
                                <div className='single-form'>
                                    <input 
                                     value={Message}
                                     onChange={(e) => setMessage(e.target.value)}
                                    type="text" className='form-control' placeholder='Message...' />
                                </div>
                            </div>
                            
                            <div className='col-12'>
                                
                                <div>
                                    <button 
                                    onClick={createReachusEnquiry}
                                    type='submit' class="upgrade btn block mt-3" style={{backgroundColor:"lavender",color:"black"}}>
                                       Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className='col-md-7 col-12 mt-3 mb-3' data-aos="zoom-in" data-aos-duration="3000">
                <div className='form-img'>
                    <img src='../Assets/contact1.jpg' alt="Contact" className='img-fluig' />
                </div>
            </div>
        </div>
    </Container> 


    <section className='contact-form'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-5' >

                    {/* <img src="../Assets/contacticon.png" alt="" className='contact-img2'/> */}

                    <h5 className='text-white' data-aos="zoom-in" data-aos-duration="3000">Fill out the form  to join our team of dedicated educators,
                        and take the next step in your professional journey. </h5>

                    {/* <h4 style={{color:"white"}}> please send your resume to</h4>  <a href="">email@example.com</a> */}
                  
                   <div>
                  
                   </div>
                </div>
                
         {/* Feedback Form */}

                <div className='col-md-7' data-aos="zoom-in" data-aos-duration="3000">
                    <form className='contact-form' >

                        <div className='form-bg1'>

                            <h1 className='career'>Feedback Form</h1>

                            <div className='row'>

                                <div className='col-12'>
                                    <div className='single-form'>
                                        <input
                                        onChange={(e) => setFeedbackName(e.target.value)}
                                        value={FeedbackName}
                                         type="text" className='form-control' placeholder='Full Name...' />
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className='single-form'>
                                        <input 
                                        onChange={(e) => setFeedbackEmail(e.target.value)}
                                        value={FeedbackEmail}
                                        type="email" className='form-control' placeholder='Email Address...' />
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className='single-form'>
                                        <input
                                        onChange={(e) => setFeedbackPhone(e.target.value)}
                                        value={FeedbackPhone}
                                         type="tel" className='form-control' placeholder='Phone Number...' />
                                    </div>
                                </div>

                            </div>

                            <div className='col-12'>
                                <div className='single-form'>
                                    <input
                                    onChange={(e) => setFeedbackMessage(e.target.value)}
                                    value={FeedbackMessage}
                                     type="text" className='form-control' placeholder='Message...' />
                                </div>
                            </div>

                            <div className='col-12'>
                                <div className='single-form'>
                                    <button 
                                     onClick={createFeedback}
                                    type='submit' className='btns'>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>


                    </form>
                </div>

            </div>
        </div>

    </section>

</div>

  )
}

export default Contact