import React, { useState,useEffect } from 'react';
import "../Styles/Home.css"
import Container from 'react-bootstrap/esm/Container'
import Card from 'react-bootstrap/Card';
import OwlCarousel from 'react-owl-carousel'
import { Link } from 'react-router-dom';
import Carousel from "react-bootstrap/Carousel";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { ImQuotesRight } from "react-icons/im";

import { FaArrowCircleDown, FaArrowCircleUp, FaStar } from "react-icons/fa";

import { Image } from 'react-bootstrap';

import Aos from "aos";
import axios from 'axios';

import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = () => {
  const navigate = useNavigate();

  const options = {
    margin: 30,
    responsiveclassName: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 5000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };


  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  }, []);


  //GET (Home Slider Carousel)

  const [HomeCarousel, setHomeCarousel] = useState([]);

  const getHomeCarousel  = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/home/gethomebanner");
      if (res.status === 201) {
        setHomeCarousel(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };


   //GET (About University)

   const [AboutUniversity, setAboutUniversity] = useState([]);

   const getAboutUniversity = async () => {
     try {
       let res = await axios.get("https://theuniversityoftrinity.com/api/home/gethomefeature");
       if (res.status === 201) {
         setAboutUniversity(res.data.data1);
       }
     } catch (error) {
       alert(error.response.data.error); 
     }
   };

   //GET (Home Ourcourse)

  const [Homecourse, setHomecourse] = useState([]);
   console.log('Homecourse>>>>>>>>>>',Homecourse) 
  const getHomecourse = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/home/gethomecourse");
      if (res.status === 201) {
        setHomecourse(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

   //GET (Home Video)

   const [HomeVideo, setHomeVideo] = useState([]);

   const getHomeVideo = async () => {
     try {
       let res = await axios.get("https://theuniversityoftrinity.com/api/home/gethomevideo");
       if (res.status === 201) {
         setHomeVideo(res.data.data1);
       }
     } catch (error) {
       alert(error.response.data.error); 
     }
   };

   
    //GET (Testimonial)

   const [Testimonial, setTestimonial] = useState([]);

   const getTestimonial = async () => {
     try {
       let res = await axios.get("https://theuniversityoftrinity.com/api/home/gettestimonial");
       if (res.status === 201) {
         setTestimonial(res.data.data1);
       }
     } catch (error) {
       alert(error.response.data.error); 
     }
   };
 
  console.log("testimonial",Testimonial);


   //GET (Home Events)

  const [HomeEvent, setHomeEvent] = useState([]);

  const getHomeEvent  = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/home/gethomeevent");
      if (res.status === 201) {
        setHomeEvent(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

  //GET

  const [Course, setCourse] = useState([]);

  const getCourse = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/service/getservicecourse");
      if (res.status === 201) {
        setCourse(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

 //GET (Service)

 const [Service, setService] = useState([]);

 const getService = async () => {
   try {
     let res = await axios.get("https://theuniversityoftrinity.com/api/service/getservice");
     if (res.status === 201) {
       setService(res.data.data1);
     }
   } catch (error) {
     alert(error.response.data.error);
   }
 };

 useEffect(() => {
   getService();
 }, []);

  useEffect(() => {
    getHomeCarousel();
    getAboutUniversity();
    getHomecourse();
    getHomeVideo();
    getTestimonial();
    getHomeEvent();
    getCourse();
  }, []);
 

   //MODAL (Functionality for viewing the model)

  //  const [showMore, setShowMore] = useState(false);
  //  const [selectedObj, setSelectedObj] = useState({})
 
  //========== modal======================

  //  const [show, setShow] = useState(false);
  //  const handleClose = () => setShow(false);
  //  const handleShow = (data) => {
  //      setShow(true);

  //     }
  //  setSelectedObj(data)

 //========== modal1======================

  //   const [show1, setShow1] = useState(false);
  //  const handleClose1 = () => setShow(false);
  //  const handleShow1 = (data) => {
  //   setShow(true); 
  //  }
  
  //========== modal2======================

  //  const [show1, setShow1] = useState(false);
  //  const handleClose1 = () => setShow1(false);
  //  const handleShow1 = (data) => {
  //      setShow1(true);
  //  }

  //========== modal3======================

  //  const [show2, setShow2] = useState(false);
  //  const handleClose2 = () => setShow2(false);
  //  const handleShow2 = (data) => {
  //      setShow2(true);
  //  }


  // Scroll Up Icon Functionality

  // const [isScrollUp, setIsScrollUp] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY === 0) {
  //       setIsScrollUp(false);
  //     } else if (
  //       window.innerHeight + window.scrollY >=
  //       document.body.scrollHeight
  //     ) {
  //       setIsScrollUp(true);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const scrollTo = () => {
  //   if (isScrollUp) {
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //   } else {
  //     window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  //   }
  //   setIsScrollUp(!isScrollUp);
  // };

  
  return (

    <div>

      {/* Fade Carousel */}

    <Container>

      <div>
        <Carousel fade>

        {HomeCarousel?.map((item, i) => {
                return (

          <Carousel.Item>
            {/* <img src="../Assets/carousel-image1.jpg" className="carousel-img-main" /> */}

            <img
                    src={`https://theuniversityoftrinity.com/HomeBanner/${item?.bannerImg}`}
                    className="carousel-image-mobileres"
                    alt=""
                    height="500px"
                    width="100%"

            />

            <Carousel.Caption>
              <h3 className='carousel-text'>{item?.bannerTitle}</h3>
              <p className='carousel-description'>{item?.bannerText}</p>
            </Carousel.Caption>
          </Carousel.Item>

        );
        })}

      </Carousel>
     </div>

   </Container>


      {/* About the University */}

      <div className='container'>

      {AboutUniversity?.map((item, i) => {
                return (

        <div className='row mt-3 mb-3'>

            <div className='col-md-6 mt-3 mb-3'  data-aos="zoom-in" data-aos-duration="3000">
              <p className='fs-2'><b>A Few Words About the University</b></p>
              <p>{parse(item?.featuredesc)}</p>
              <button className='abt-university-btnn'><a href='/about' className='abt-university'>Learn More</a></button>
            </div>

            <div className='col-md-6 mt-3 mb-3' data-aos="zoom-in" data-aos-duration="3000">
              {/* <img src="../Assets/carousel-image1.jpg" alt="" className='img-fluidd abt-img' /> */}
              <img
                            src={`https://theuniversityoftrinity.com/HomeFeature/${item?.featureimg}`}
                            alt="pic"
                            className='img-fluidd abt-img'
                          />{" "}
            </div>

        </div>

        );
        })}

      </div>

      {/* Our Featured Courses */}
  
        <Container>

        <p className='text-center fs-1'><b>Our Courses</b></p>

        {
          Homecourse?.map((ele) =>{
            return(
              <div className='row mt-3 mb-3'  data-aos="zoom-in" data-aos-duration="3000">

              <div className='col-md-4 mt-3 mb-3'>
                <img src={`https://theuniversityoftrinity.com/HomeCourse/${ele?.image}`} 
                alt="" className='img-fluid prgrm-img' />
              </div>
  
              <div className='col-md-8 mt-3 mb-3'>
  
                <div className=' gap-2 service-mainbox w-100'>
                
                  <p className='fs-5 text-white'>{ele?.desc}</p>
  
                  <div className=''>
  
                  {Course?.map((item, i) => {
                    return (
  
                    <div className='row service-contents-card mt-3 mb-3'  data-aos="zoom-in" data-aos-duration="3000">
  
                      <div className='col-md-4 mb-3' >
                        <img src= {`https://theuniversityoftrinity.com/ServiceCourse/${item?.courseimg}`} alt="" className='couse-img' />
                      </div>
  
                      <div className='col-md-8 mb-3'>
  
                       
                        <h5 className="card-title mt-2 mb-2 "><a href='/service' className='course-name'>{item?.coursetitle}</a></h5>
  
                        <p className="card-text mb-3  serviceDesc">
                         
                            <p>{parse(item?.coursedesc)}</p>         
                                            
                        </p>
  
                      </div>
  
                    </div>
                   
                  );
                  })}
  
                    {/* <div className='row service-contents-card mt-3 mb-3'  data-aos="zoom-in" data-aos-duration="3000">
  
  
                      <div className='col-md-4 mb-3' >
                        <img src="../Assets/logo.jpg" alt="" className='couse-img' />
                      </div>
  
                      <div className='col-md-8 mb-3'>
  
                        
                        <h5 className="card-title mt-2 mb-2 "><a href='/service' className='course-name'>DIPLOMA COURSES :</a></h5>
  
                        <p className="card-text mb-3  serviceDesc">
                          <ul>
                            <li>Diploma in Missions and Church planting (DMC)</li>
                            <li>Diploma in Evangelism (D.E)</li>
                            <li>Diploma in Theology (D.Th)</li>
                          </ul>                      
                        </p>
  
                      </div>
  
                    </div> */}
  
  
                    {/* <div className='row service-contents-card mt-3 mb-3'  data-aos="zoom-in" data-aos-duration="3000">
  
  
                      <div className='col-md-4 mb-3' >
                        <img src="../Assets/logo.jpg" alt="" className='couse-img' />
                      </div>
  
                      <div className='col-md-8 mb-3'>
  
                   
                        <h5 className="card-title mt-2 mb-2 "><a href='/service' className='course-name'>DEGREE COURSES :</a></h5>
  
                        <p className="card-text mb-3  serviceDesc">
                          <ul>
                            <li>Bachelor of Theology(B.Th.)</li>
                            <li>Bachelor of Divinity(B.D)</li>
                            <li>Bachelor of Biblical Studies(BBS)</li>
                          </ul>                      
                        </p>
  
                        
                      </div>
  
                    </div> */}
  
  
                    {/* <div className='row service-contents-card mt-3 mb-3'  data-aos="zoom-in" data-aos-duration="3000">
  
  
                      <div className='col-md-4 mb-3' >
                        <img src="../Assets/logo.jpg" alt="" className='couse-img' />
                      </div>
  
                      <div className='col-md-8 mb-3'>
  
                   
                        <h5 className="card-title mt-2 mb-2 "><a href='/service' className='course-name'>DOCTORAL RESEARCH and STUDIES :</a></h5>
  
                        <p className="card-text mb-3  serviceDesc">
                          <ul>
                            <li>Doctor of Philosophy Ph.D.(Phil)</li>
                            <li>Doctor of Divinity(D.D)</li>
                            <li>Doctor of Divinity(Honorary for the eligible Candidates)</li>
                          </ul>                      
                        </p>
  
                       
                      </div>
  
                    </div> */}
  
                 <a 
                onClick={() => navigate('/service', { state: { id: '1' } })}
                //  href="/service"
                 ><button className='home-course-viewmore'>View More</button></a>   
  
                  </div>
                </div>
  
              </div>
  
            </div>
            )
          })
        }
         

      </Container>

      
     {/* Services */}
      
          <Container>

          <p className='text-center fs-1'><b>Our Services</b></p>

        <div className='row justify-content-center align-center m-auto mt-3 mb-5'>
            
        <OwlCarousel className="owl-theme"  loop={false} margin={5} nav {...options}>
      
        {Service?.map((item, i) => {
              return (

        <div className='item'>
    
                  <Card className="text-center entire-cardd">
                      <Card.Header className="custom-card-header">
                        <b>{item?.servicename}</b>
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                          <p className='serviceDesc'>{parse(item?.servicedescription || "")}</p>
                        </Card.Text>
                        <Button variant="primary"><a href='/service' className='service-viewmore'>View More</a></Button>
                      </Card.Body>
                    </Card>
      
        </div>

        );
        })}


        {/* <div className='item'>
        
                    <Card className="text-center entire-cardd">
                      <Card.Header className="custom-card-header">
                        <b>Discipleship Meetings</b>
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                         <p className='serviceDesc'>If we have any hope of recovering the New Testament picture of the church, we will need to recover personal mentoring from the ash heap of history. Some signs look good, especially in non-western churches. In the west, we just have too many important things to do. We can’t find time for meeting with younger Christians to build love relationships and to train them in the word, character development, and how to build a personal ministry.</p>
                        </Card.Text>
                        <Button variant="primary"><a href='/service' className='service-viewmore'>View More</a></Button>
                      </Card.Body>
                    </Card>
        
        </div>

        <div className='item'>
        
                    <Card className="text-center entire-cardd">
                      <Card.Header className="custom-card-header">
                        <b>Leadership Training</b>
                      </Card.Header>

                      <Card.Body>
                        <Card.Text>
                          <p className='serviceDesc'>The leader of any team, department, or organization has great impact on the results and effectiveness of the people they lead. The leader sets the example that everyone follows. As the leader goes, so the followers go. The leader needs to be properly trained in order to model good leadership. If the leader is not effective, then the followers and their results will most likely be hindered.</p>
                        </Card.Text>
                        <Button variant="primary"><a href='/service' className='service-viewmore'>View More</a></Button>
                      </Card.Body>
                    </Card>
        
        </div>

        <div className='item'>

                  <Card className="text-center entire-cardd">
                      <Card.Header className="custom-card-header">
                        <b>Church Planting</b>
                      </Card.Header>

                      <Card.Body>
                        <Card.Text>
                          <p className='serviceDesc'>Church planting is a process that results in a new (local) Christian church being established. It should be distinguished from church development, where a new service, new worship center or fresh expression is created that is integrated into an already established congregation.</p>
                        </Card.Text>
                        <Button variant="primary"><a href='/service' className='service-viewmore'>View More</a></Button>
                      </Card.Body>
                    </Card>
        
        </div>

            
        <div className='item'>

            <Card className="text-center entire-cardd">
                <Card.Header className="custom-card-header">
                  <b>Church Planting</b>
                </Card.Header>

                <Card.Body>
                  <Card.Text>
                    <p className='serviceDesc'>Church planting is a process that results in a new (local) Christian church being established. It should be distinguished from church development, where a new service, new worship center or fresh expression is created that is integrated into an already established congregation.</p>
                  </Card.Text>
                  <Button variant="primary"><a href='/service' className='service-viewmore'>View More</a></Button>
                </Card.Body>
              </Card>

            </div> */}

        </OwlCarousel>
          
        </div>
          
      </Container>


    {/* Video */}

      <Container>

          <div>

          {HomeVideo?.map((item, i) => {
                    return (
                      <a href={`https://theuniversityoftrinity.com/HomeVideo/${item?.homevideo}`} target='_blank'>
              <video controls autoPlay muted loop className="video">
                  {/* <source src="../Assets/home-video.mp4" type="video/mp4" /> */}
                  <source src={`https://theuniversityoftrinity.com/HomeVideo/${item?.homevideo}`} type="video/mp4" />
             </video>
             </a>
             
            );
          })}


        {HomeVideo?.map((item, i) => {
                            return (
                              
            <div  className='dhfihsef'>
              <p className='video-text'>Watch Our Video</p>
              <a href={`https://theuniversityoftrinity.com/HomeVideo/${item?.homevideo}`} target='_blank'>
              <button className='video-view-btnn' 
             // onClick={setShow}
              >
                View</button>
                </a>
            </div>
        );
        })}

          </div>

    </Container>


      {/* Events */}

  <div className='container'>

       <p className='fs-1 text-center'><b>Events</b></p>

        {/* <div className='row me-0' data-aos="zoom-in" data-aos-duration="3000">              

              <div class="col-md-3" data-aos="zoom-in" data-aos-duration="3000">
               <div className='awusd'>
               <div class='afdsiu'>
                  <img src="../Assets/carousel-image1.jpg" alt="" class="events"/>
                </div>
                <div class='col-md-12 d-flex justify-content-center'>
                  <div class='col-md-3'>
                    <div class='datebox'>
                      <div>24<br/>Sept</div>
                      <div>11:34 pm</div>
                    </div>
                  </div>
                  <div class='col-md-9'>
                    <p class='text-center event-text'><b>Ministry Programs</b></p>
                  </div>
                </div>
               </div>
              </div>

              <div class="col-md-3" data-aos="zoom-in" data-aos-duration="3000">
               <div className='awusd'>
               <div class='afdsiu'>
                  <img src="../Assets/carousel-image1.jpg" alt="" class="events"/>
                </div>
                <div class='col-md-12 d-flex justify-content-center'>
                  <div class='col-md-3'>
                    <div class='datebox'>
                      <div>24<br/>Sept</div>
                      <div>11:34 pm</div>
                    </div>
                  </div>
                  <div class='col-md-9'>
                    <p class='text-center event-text'><b>Christmas Program</b></p>
                  </div>
                </div>
               </div>
              </div>

              <div class="col-md-3" data-aos="zoom-in" data-aos-duration="3000">
               <div className='awusd'>
               <div class='afdsiu'>
                  <img src="../Assets/carousel-image1.jpg" alt="" class="events"/>
                </div>
                <div class='col-md-12 d-flex justify-content-center'>
                  <div class='col-md-3'>
                    <div class='datebox'>
                      <div>24<br/>Sept</div>
                      <div>11:34 pm</div>
                    </div>
                  </div>
                  <div class='col-md-9'>
                    <p class='text-center event-text'><b>Easter Party</b></p>
                  </div>
                </div>
               </div>
              </div>

              <div class="col-md-3" data-aos="zoom-in" data-aos-duration="3000">
               <div className='awusd'>
               <div class='afdsiu'>
                  <img src="../Assets/carousel-image1.jpg" alt="" class="events"/>
                </div>
                <div class='col-md-12 d-flex justify-content-center'>
                  <div class='col-md-3'>
                    <div class='datebox'>
                      <div>24<br/>Sept</div>
                      <div>11:34 pm</div>
                    </div>
                  </div>
                  <div class='col-md-9'>
                    <p class='text-center event-text'><b>Church Programs</b></p>
                  </div>
                </div>
               </div>
              </div>

        </div>  */}


 <OwlCarousel className="owl-theme"  loop={false} margin={5} nav {...options}>

 {HomeEvent?.map((item, i) => {
                    return (

  <div class="item">
    <div class='awusd'>
      <div class='afdsiu'>
        {/* <img src="../Assets/carousel-image1.jpg" alt="" class="events"/> */}
        <Image
                        src={`https://theuniversityoftrinity.com/HomeEvent/${item?.eventimg}`}
                        alt="pic"
                        class="events"                   
         /> 
      </div>
      <div class='col-md-12 d-flex justify-content-center'>
        <div class='col-md-3'>
          <div class='datebox'>
            <div>{item?.eventdate}</div>
            <div>{item?.eventtime}</div>
          </div>
        </div>
        <div class='col-md-9'>
          <p class='text-center event-text'><b>{item?.eventname}</b></p>
        </div>
      </div>
    </div>
  </div>

);
})}


  {/* // <div class="item">
  //   <div class='awusd'>
  //     <div class='afdsiu'>
  //       <img src="../Assets/carousel-image1.jpg" alt="" class="events"/>
  //     </div>
  //     <div class='col-md-12 d-flex justify-content-center'>
  //       <div class='col-md-3'>
  //         <div class='datebox'>
  //           <div>24<br/>Sept</div>
  //           <div>11:34 pm</div>
  //         </div>
  //       </div>
  //       <div class='col-md-9'>
  //         <p class='text-center event-text'><b>Easter Party</b></p>
  //       </div>
  //     </div>
  //   </div>
  // </div> */}


  {/* <div class="item">
    <div class='awusd'>
      <div class='afdsiu'>
        <img src="../Assets/carousel-image1.jpg" alt="" class="events"/>
      </div>
      <div class='col-md-12 d-flex justify-content-center'>
        <div class='col-md-3'>
          <div class='datebox'>
            <div>24<br/>Sept</div>
            <div>11:34 pm</div>
          </div>
        </div>
        <div class='col-md-9'>
          <p class='text-center event-text'><b>Church Programs</b></p>
        </div>
      </div>
    </div>
  </div> */}

</OwlCarousel>
</div>  


    {/* Testimonials */}
     
     <Container>

      <div>
        <p className='fs-1 text-center mt-5 mb-3'><b>Testimonials</b></p>
      </div>

        <div className="containt">
      <OwlCarousel
        className="owl-theme"
        loop
        margin={100}
        nav
        items={1}
        autoplay
        autoplayTimeout={10000}
      >

    
      {Testimonial?.map((item, i) => {
                    return ( 

       <div className="item">
        <div className="contact-bxx">
         
          <h3 className="sect-title-3 text-white">
            {item?.testimonialtitle}
            </h3>

          <p className='text-white mt-3'>
           {item?.testimonialdescription}
          </p>
        </div>
      </div>

     );
    })} 


        {/* <div className="item" >
        <div className="contact-bxx">
          
          <h3 className="sect-title-3 text-white">Maria</h3>
          <div className="star-quotes-group d-flex justify-content-between">
            <div className="star-group">
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
            </div>
            <div className="quotes">
              <ImQuotesRight />
            </div>
          </div>
          <p className='text-white mt-3'>
            The course content is knowledgeable & great for learning. Thanks to University of Trinity.
          </p>
        </div>
        </div> */}

        {/* <div className="item" >
        <div className="contact-bxx">
          
          <h3 className="sect-title-3 text-white">John</h3>
          <div className="star-quotes-group d-flex justify-content-between">
            <div className="star-group">
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
            </div>
            <div className="quotes">
              <ImQuotesRight />
            </div>
          </div>
          <p className='text-white mt-3'>
            This Education is very informative & covered almost all the topics in depth. I have gained enough knowledge and confidence from this course.
          </p>
        </div>
        </div> */}

      </OwlCarousel>
      </div>
    </Container>  


    {/* Modal for Service Description*/}


          {/* <Modal show={show} onHide={handleClose}>

            
              <Modal.Body>
                  
                <video controls autoPlay muted loop className="video">
                    <source src="../Assets/home-video.mp4" type="video/mp4" />
              </video>

              </Modal.Body>

              <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                      Close
                  </Button>
              </Modal.Footer>

        </Modal> */}


         {/* Modal for Service Description*/}


         {/* <Modal show={show} onHide={handleClose}>

              <Modal.Header closeButton>
                  <Modal.Title>POST GRADUATE COURSES:</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                  
                  <p className="card-text mb-3 ">
                    <ul>
                      <li>Master of Divinity(M.Div)</li>
                      <li>Master of Theology (New Testment) M.Th (NT)</li>
                      <li>Master of Theology (Old Testment) M.Th (OT)</li>
                    </ul>                      
                  </p>

              </Modal.Body>

              <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                      Close
                  </Button>
              </Modal.Footer>

        </Modal>


        <Modal show={show1} onHide={handleClose1}>

              <Modal.Header closeButton>
                  <Modal.Title>DIPLOMA COURSES:</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                  
                  
                  <p className="card-text mb-3 ">
                  <ul>
                      <li>Diploma in Missions and Church planting (DMC)</li>
                      <li>Diploma in Evangelism (D.E)</li>
                      <li>Diploma in Theology (D.Th)</li>
                    </ul>                   
                  </p>

              </Modal.Body>

              <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose1}>
                      Close
                  </Button>
              </Modal.Footer>

        </Modal>



        <Modal show={show2} onHide={handleClose2}>

              <Modal.Header closeButton>
                  <Modal.Title>DIPLOMA COURSES:</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                  
                  
                  <p className="card-text mb-3 ">
                    <ul>
                      <li>Bachelor of Theology(B.Th.)</li>
                      <li>Bachelor of Divinity(B.D)</li>
                      <li>Bachelor of Biblical Studies(BBS)</li>
                    </ul>                
                  </p>

              </Modal.Body>

              <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose2}>
                      Close
                  </Button>
              </Modal.Footer>

        </Modal> */}


    {/* Scroll Down Icon Functionality */}


  {/* 
    <div
          id="scroll-button"
          className={isScrollUp ? "scroll-up" : "scroll-down"}
          onClick={scrollTo}
        >
          {isScrollUp ? (
            <FaArrowCircleUp style={{ fontSize: "45px" }} />
          ) : (
            <FaArrowCircleDown style={{ fontSize: "45px" }} />
          )} 
    </div> */}

  </div>

  )
}

export default Home