import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
//import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";

const AdminGalleryVideo = () => {

    // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


   //Frontend Integration

  const [GalleryVideo, setGalleryVideo] = useState("");

  //POST

  const createGalleryVideo = async () => {
    try {
      const config = {
        url: "/creategalleryvideo",
        method: "post",
        baseURL: "https://theuniversityoftrinity.com/api/gallery",
        headers: { "content-type": "multipart/form-data" },
        data: {
          galleryvideo: GalleryVideo,       
         
        },
      };

      let res = await axios(config);
      console.log("res",res)
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getGalleryVideo();
        setGalleryVideo("");
       
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //GET

  const [GalleryVideoContent, setGalleryVideoContent] = useState([]);

  const getGalleryVideo= async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/gallery/getgalleryvideo");
      if (res.status === 201) {
        setGalleryVideoContent(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

  //UPDATE
  
const [GalleryVideoContentId, setGalleryVideoContentId] = useState({})

  const editGalleryVideo = async()=>{

    try {
    const config = {
      url: "/updategalleryvideo/"+GalleryVideoContentId?._id,
      method: "put",
      baseURL: "https://theuniversityoftrinity.com/api/gallery",
      headers: { "content-type": "multipart/form-data" },
      data: {
        galleryvideo: GalleryVideo,  
      },
    };
    let res = await axios(config);
    if (res.status === 201) {
      alert(res.data.sucess);
      handleClose4()
      getGalleryVideo();
      setGalleryVideo("");
    }
  }
   catch (error) {
    alert(error.response.data.error);
  }
  }

  //DELETE

  const deleteGalleryVideo = async () => {
    try {
      let res = await axios.delete(`https://theuniversityoftrinity.com/api/gallery/deletegalleryvideo/${GalleryVideoContentId?._id}`);
      if (res.status === 201) {
        alert(res.data.message);
        getGalleryVideo();
        handleClose5()
      }
    } catch (error) {
      alert(error.response.data.message)
    }
  };

  useEffect(() => {
    getGalleryVideo();
  }, []);
 

  return (
    <div>

    <div className="customerhead p-2">
               <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Gallery Video</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add
            </button>
          </div>

        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Video</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>

            {GalleryVideoContent?.map((item, i) => {
                    return (

              <tr>
                <td>{i+1}</td>
                <td>
                   <video
                        src={`https://theuniversityoftrinity.com/GalleryVideo/${item?.galleryvideo}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      /> 
                </td>
               
                <td>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <BiSolidEdit
                        className=""
                        style={{ cursor: "pointer", fontSize: "20px" }}
                        onClick={() =>{
                          handleShow4();
                          setGalleryVideoContentId(item);
                        } }

                      />{" "}
                    </div>

                    <div>
                      <AiFillDelete
                        className="text-danger"
                        style={{ cursor: "pointer", fontSize: "20px" }}
                        onClick={() => {
                          handleShow5();
                          setGalleryVideoContentId(item);
                        }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
         
            );
            })}

            </tbody>
          </Table>
          </div>

          {/* Add Package modal for Slider */}

          <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>

            <Modal.Header
              closeButton
            >
              <Modal.Title>Add Gallery Video</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Gallery Video</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    accept="video/*" 
                   onChange={(e) => setGalleryVideo(e.target.files[0])}
                  />
                </div>
              </div>

            </Modal.Body>

            <Modal.Footer>
              <div className="d-flex">
                <Button
                  className="mx-2 modal-close-btn"
                  variant=""
                  onClick={handleClose3}
                >
                  Close
                </Button>
                <Button
                  className="mx-2 modal-add-btn"
                  variant=""
                 onClick={createGalleryVideo}
                >
                  Add
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* Edit Package modal for Slider */}

          <Modal
            show={show4}
            onHide={handleClose4}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header
              closeButton
            >
              <Modal.Title style={{ color: "black" }}>Edit Gallery Video</Modal.Title>
            </Modal.Header>


            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Gallery Video</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    accept="video/*" 
                   onChange={(e) => setGalleryVideo(e.target.files[0])}
                  />
                </div>
              </div>


            </Modal.Body>

            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose4}
              >
                Close
              </Button>
              <Button variant="" className="modal-add-btn"
               onClick={editGalleryVideo}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          {/*Delete Package modal for Slider */}

          <Modal
            show={show5}
            onHide={handleClose5}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >

            <Modal.Header
              closeButton
            >
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>


            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <p className="fs-4" style={{ color: "red" }}>
                    Are you sure?
                    <br /> you want to delete this data?
                  </p>
                </div>
              </div>
            </Modal.Body>


            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose5}
              >
                Close
              </Button>
              <Button
                variant=""
                className="modal-add-btn"
               onClick={deleteGalleryVideo}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
    </div>
  </div>
  )
}

export default AdminGalleryVideo