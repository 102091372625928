import React, {useEffect, useState } from 'react';
import '../Styles/Gallery.css'
import Carousel from 'react-bootstrap/Carousel';
import { Container } from 'react-bootstrap';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Aos from "aos";
import axios from 'axios';

const Gallery = () => {

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  }, []);


  //GET

  const [GalleryImage, setGalleryImage] = useState([]);

  const getGalleryImage = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/gallery/getgalleryimage");
      if (res.status === 201) {
        setGalleryImage(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

  //GET

  const [GalleryVideoContent, setGalleryVideoContent] = useState([]);

  const getGalleryVideo= async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/gallery/getgalleryvideo");
      if (res.status === 201) {
        setGalleryVideoContent(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

  useEffect(() => {
    getGalleryImage();
    getGalleryVideo();
  }, []);


  return (
   
    <div className='gallery-mainpage'>

      <section className='about-bannerimg'
                style={{
                    backgroundImage: `url("/Assets/banner.avif")`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "200px",
                    width: "100%"
                }}
            >
                <div className="container ">

                    <div className='breadcrumbt'>
                        <div>
                            <h1 className='commonbanner-heading'>Gallery</h1>             
                        </div>
                        <div className='mbl-responsive-breadcrumbs'>
                            <Breadcrumb>
                                <FontAwesomeIcon icon={faHouse} style={{ fontSize: '14px', marginTop: '0px', color: "rgb(0,105,176)", padding:"5px" }} />
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active className='abtus-small-heading' style={{color:"white"}}>Gallery</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    
                </div>
    </section>

            <Container>

           
            <div className="row mt-4 mb-4" data-aos="zoom-in" data-aos-duration="3000">
    
            {GalleryImage?.map((item, i) => {
                    return (
    
              <div className='col-md-4 mt-3' data-aos="zoom-in" data-aos-duration="3000">
                <img src={`https://theuniversityoftrinity.com/GalleryImage/${item?.galleryimage}`}  alt="" className='gallery-img' />
              </div>    
    
              );
            })}
            </div>
        

                {/* Video */}

            <div className="row mt-4 mb-4">

            {GalleryVideoContent?.map((item, i) => {
                    return (
    
              <div className='col-md-4' data-aos="zoom-in">  
                
              <video
                        src={`https://theuniversityoftrinity.com/GalleryVideo/${item?.galleryvideo}`}
                        alt="pic"
                        className="video"
                        controls
                        autoPlay                       
                        muted 
                        loop                     
                /> 

              </div>
    
              );
            })}
    
            </div> 
    
          </Container>
          
        </div>

  )
}

export default Gallery