import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const AdminContactDescription = () => {

     // Ck Editor Code
  const [Desc, setDesc] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };


  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


  //Frontend Integration

//   const [WhychooseusImage, setWhychooseusImage] = useState("");
//   const [WhychooseusText, setWhychooseusText] = useState("");

  //POST

//   const createWhychooseus = async () => {
//     try {
//       const config = {
//         url: "/createwhychooseus",
//         method: "post",
//         baseURL: "https://theuniversityoftrinity.com/api/homewhychooseus",
//         headers: { "content-type": "multipart/form-data" },
//         data: {
//           image: WhychooseusImage,       
//           text: WhychooseusText,
//           description: Desc,
//         },
//       };

//       let res = await axios(config);
//       console.log("res",res)
//       if (res.status === 200) {
//         alert(res.data.status);
//         handleClose3();
//         getWhychooseus();
//         setWhychooseusImage("");
//         setWhychooseusText("");
//         setDesc("");
//       }
//     } catch (error) {
//       alert(error.response.data.error);
//     }
//   };


  //GET

//   const [Whychooseus, setWhychooseus] = useState([]);

//   const getWhychooseus = async () => {
//     try {
//       let res = await axios.get("https://theuniversityoftrinity.com/api/homewhychooseus/getwhychooseus");
//       if (res.status === 201) {
//         setWhychooseus(res.data.data1);
//       }
//     } catch (error) {
//       alert(error.response.data.error); 
//     }
//   };


  //UPDATE
  
// const [WhychooseusId, setWhychooseusId] = useState({})

//   const editWhychooseus = async()=>{

//     try {
//     const config = {
//       url: "/updatewhychooseus/"+WhychooseusId?._id,
//       method: "put",
//       baseURL: "https://theuniversityoftrinity.com/api/homewhychooseus",
//       headers: { "content-type": "multipart/form-data" },
//       data: {
//         image: WhychooseusImage,
//         text: WhychooseusText,
//         description: Desc,
//       },
//     };
//     let res = await axios(config);
//     if (res.status === 201) {
//       alert(res.data.success);
//       handleClose4()
//       getWhychooseus();
//       setWhychooseusImage("");
//       setWhychooseusText("");
//       setDesc("");
//     }
//   }
//    catch (error) {
//     alert(error.response.data.error);
//   }
//   }

  //DELETE

//   const deleteWhychooseus = async () => {
//     try {
//       let res = await axios.delete(`https://theuniversityoftrinity.com/api/homewhychooseus/deletewhychooseus/${WhychooseusId?._id}`);
//       if (res.status === 201) {
//         alert(res.data.message);
//         getWhychooseus();
//         handleClose5()
//       }
//     } catch (error) {
//       alert(error.response.data.message)
//     }
//   };


//   useEffect(() => {
//     getWhychooseus();
//   }, []);
 

  return (
    
    <div>


    <div className="customerhead p-2">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="header-c ">Contact Description</h2>
              <div className="d-flex gap-3">
                <button className="admin-add-btn" onClick={handleShow3}>
                  Add 
                </button>
              </div>
            </div>
    
            <div className="mb-3">
              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
    
                  <tr>
                    <th>Sl.No</th>
                    <th>Image</th>
                 
                    {/* <th>Text</th> */}
                   <th>Description</th>
                    <th>Action</th>
                  </tr>
    
                </thead>
    
                <tbody>
                 
                {/* {Whychooseus?.map((item, i) => {
                    return (
                  */}
                      <tr>
                        <td></td>
                        <td>
                           img
                          {/* <img
                            src={`https://theuniversityoftrinity.com/Whychooseus/${item?.image}`}
                            alt="pic"
                            style={{ width: "75px", height: "75px" }}
                          />{" "} */}
                        </td>
                       
                        <td style={{ paddingTop: "10px" }}>description</td>
                        {/* <td style={{ paddingTop: "10px" }}>{parse(item?.description)}</td> */}
    
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className=""
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                 
                                }}
                                onClick={() =>{
                                  handleShow4()
                                // setWhychooseusId(item)
                                }
                                   
                                  }
                              />{" "}
                            </div>
                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                
                                }}
                                onClick={() => {
                                  handleShow5();
    
                                 // setWhychooseusId(item)
    
                                  // setDatas(item?._id);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
    
                    {/* );
                  })}
              */}
                </tbody>
              </Table>
            </div>
    
            {/* Add Package modal for Slider */}
    
            <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
              <Modal.Header closeButton>
                <Modal.Title>Add Contact Description</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Image</label>
                    <input
                      type="file"
                      name=""
                      id=""
                      className="vi_0"
                      accept="image/*"
                   //   onChange={(e) => setWhychooseusImage(e.target.files[0])}
                    />
                  </div>
                </div>
              
    
                {/* <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Text</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the text"
                    //   onChange={(e) => setWhychooseusText(e.target.value)}
                    />
                  </div>
                </div> */}
    
    
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Description</label>               
                    <CKEditor editor={ClassicEditor} onChange={handleChange} />
                  </div>
                </div>
    
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <Button
                    className="mx-2 modal-close-btn"
                    variant=""
                    onClick={handleClose3}
                  >
                    Close
                  </Button>
                  <Button
                    className="mx-2 modal-add-btn"
                    variant=""
                  //  onClick={createWhychooseus}
                  >
                    Add
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
    
            {/* Edit Package modal for Slider */}
    
            <Modal
              show={show4}
              onHide={handleClose4}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  Edit Contact Description
                </Modal.Title>
              </Modal.Header>
              
              <Modal.Body>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Image</label>
                    <input
                      type="file"
                      name=""
                      id=""
                      className="vi_0"
                   //  onChange={(e) => setWhychooseusImage(e.target.files[0])}
                    />
                  </div>
                </div>
    
                
               
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Description</label>
                   
                    <CKEditor editor={ClassicEditor} onChange={handleChange} />
                  </div>
                </div>
    
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose4}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                 // onClick={editWhychooseus}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
    
            {/*Delete Package modal for Slider */}
    
            <Modal
              show={show5}
              onHide={handleClose5}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-4" style={{ color: "red" }}>
                      Are you sure?
                      <br /> you want to delete this data?
                    </p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose5}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                 //  onClick={deleteWhychooseus}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
    
     </div>


  )
}

export default AdminContactDescription