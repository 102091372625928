import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

const AdminContactFeedbackForm = () => {

    // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  //Frontend Integration

 //GET

  const [Feedback, setFeedback] = useState([]);

  const getFeedback  = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/contact/getcontactfeedback");
      if (res.status === 201) {
        setFeedback(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };


  //DELETE

  const [FeedbackId, setFeedbackId] = useState([]);

  const deleteFeedback = async () => {
    try {
      let res = await axios.delete(`https://theuniversityoftrinity.com/api/contact/deletecontactfeedback/${FeedbackId?._id}`);
      if (res.status === 200) {
        alert(res.data.status);
        getFeedback();
        handleClose5()
      }
    } catch (error) {
      alert(error.response.data.status)
    }
  };

  useEffect(() => {
    getFeedback();
  }, []);


  return (
    <div>

    <div className="customerhead p-2">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="header-c ">Feedback</h2>
              {/* <div className="d-flex gap-3">
                <button className="admin-add-btn" onClick={handleShow3}>
                  Add 
                </button>
              </div> */}
            </div>
    
            <div className="mb-3">
              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
    
                  <tr>
                    <th>Sl.No</th>
                    <th>Name</th>   
                    <th>Email</th>
                    <th>Phone Number</th> 
                    <th>Message</th>
                    <th>Action</th>
                  </tr>
    
                </thead>
    
                <tbody>
                 
                {Feedback?.map((item, i) => {
                    return (
                 
                      <tr>
                        <td>{i+1}</td>
                        <td style={{ paddingTop: "10px" }}>{item?.name}</td>
                        <td style={{ paddingTop: "10px" }}>{item?.email}</td>
                        <td style={{ paddingTop: "10px" }}>{item?.phoneno}</td>
                        <td style={{ paddingTop: "10px" }}>{item?.msg}</td>
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            {/* <div>
                              <BiSolidEdit
                                className=""
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                 
                                }}
                                onClick={() =>{
                                  handleShow4()
                                // setWhychooseusId(item)
                                }
                                   
                                  }
                              />{" "}
                            </div> */}

                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                 
                                }}
                                onClick={() => {
                                  handleShow5();
    
                                  setFeedbackId(item)
    
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
    
                     );
                  })}
                 
                </tbody>
              </Table>
            </div>
    
            {/* Add Package modal for Slider */}
    
            {/* <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
              <Modal.Header closeButton>
                <Modal.Title>Add</Modal.Title>
              </Modal.Header>
              <Modal.Body>

               
              <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Contact Name</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Contact Name"
                    //   onChange={(e) => setWhychooseusText(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Contact Email</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Contact Email"
                    //   onChange={(e) => setWhychooseusText(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Contact Number</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Contact Number"
                    //   onChange={(e) => setWhychooseusText(e.target.value)}
                    />
                  </div>
                </div>


                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Message</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Message"
                    //   onChange={(e) => setWhychooseusText(e.target.value)}
                    />
                  </div>
                </div>
                

              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <Button
                    className="mx-2 modal-close-btn"
                    variant=""
                    onClick={handleClose3}
                  >
                    Close
                  </Button>
                  <Button
                    className="mx-2 modal-add-btn"
                    variant=""
                  //  onClick={createWhychooseus}
                  >
                    Add
                  </Button>
                </div>
              </Modal.Footer>
            </Modal> */}
    
            {/* Edit Package modal for Slider */}
    
            {/* <Modal
              show={show4}
              onHide={handleClose4}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  Edit
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                
    
              <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Contact Name</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Contact Name"
                       onChange={(e) => setWhychooseusText(e.target.value)}
                    />
                  </div>
                </div>

                
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Contact Email</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Contact Email"
                      onChange={(e) => setWhychooseusText(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Contact Number</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Contact Number"
                       onChange={(e) => setWhychooseusText(e.target.value)}
                    />
                  </div>
                </div>


                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Message</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Message"
                      onChange={(e) => setWhychooseusText(e.target.value)}
                    />
                  </div>
                </div>


              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose4}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                 // onClick={editWhychooseus}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal> */}
    

        {/*Delete Package modal for Slider */}
    
            <Modal
              show={show5}
              onHide={handleClose5}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >

              <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-4" style={{ color: "red" }}>
                      Are you sure?
                      <br/> you want to delete this data?
                    </p>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose5}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={deleteFeedback}
                >
                  Delete
                </Button>
              </Modal.Footer>

            </Modal>
          </div>
    
     </div>
  )
}

export default AdminContactFeedbackForm