import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
//import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";

const AdminAboutManagementTeam = () => {

  //Frontend Integration


  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => { setShow3(false); setSocialMediaLinks([]) };
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  //Frontend Integration

  const [ManagementTeamImage, setManagementTeamImage] = useState("");
  const [ManagementTeamTitle, setManagementTeamTitle] = useState("");

  //POST

  const createManagementTeam = async () => {
    try {
      const config = {
        url: "/createmanagementteam",
        method: "post",
        baseURL: "https://theuniversityoftrinity.com/api/about",
        headers: { "content-type": "multipart/form-data" },
        data: {
          managementteamimg: ManagementTeamImage,
          managementteamname: ManagementTeamTitle,
          SocialMedialinks: JSON.stringify(SocialMediaLinks),
        },
      };

      console.log(config);

      let res = await axios(config);
      console.log("res", res)
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getManagementTeam();
        setManagementTeamImage("");
        setManagementTeamTitle("");

      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //GET

  const [ManagementTeam, setManagementTeam] = useState([]);

  const getManagementTeam = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/about/getmanagementteam");
      if (res.status === 201) {
        setManagementTeam(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //UPDATE

  const [ManagementTeamId, setManagementTeamId] = useState({})

  const editManagementTeam = async () => {

    try {
      const config = {
        url: "/updatemanagementteam/" + ManagementTeamId?._id,
        method: "put",
        baseURL: "https://theuniversityoftrinity.com/api/about",
        headers: { "content-type": "multipart/form-data" },
        data: {
          managementteamimg: ManagementTeamImage,
          managementteamname: ManagementTeamTitle
        },
      };
      let res = await axios(config);
      if (res.status === 201) {
        alert(res.data.sucess);
        handleClose4()
        getManagementTeam();
        setManagementTeamImage("");
        setManagementTeamTitle("");
      }
    }
    catch (error) {
      alert(error.response.data.error);
    }
  }

  //DELETE

  const deleteManagementTeam = async () => {
    try {
      let res = await axios.delete(`https://theuniversityoftrinity.com/api/about/deletemanagementteam/${ManagementTeamId?._id}`);
      if (res.status === 201) {
        alert(res.data.message);
        getManagementTeam();
        handleClose5()
      }
    } catch (error) {
      alert(error.response.data.message)
    }
  };

  // GET (Social Media Details)

  const [SocialMedia, setSocialMedia] = useState([]);

  const getSocialMedia = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/about/getsocialmedia");
      if (res.status === 201) {
        setSocialMedia(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getManagementTeam();
    getSocialMedia()
  }, []);


  // Add Social Media Link 

  const [SelectedSocialMedia, setSelectedSocialMedia] = useState("");
  const MediaDetails = SelectedSocialMedia ? JSON.parse(SelectedSocialMedia) : null;

  const [SocialMediaLink, setSocialMediaLink] = useState("");
  const [SocialMediaLinks, setSocialMediaLinks] = useState([]);

  const AddSocialMediaLinks = () => {

    // Validation checks
    if (!SelectedSocialMedia) {
      alert("Please select a media type.");
      return;
    }

    if (!SocialMediaLink) {
      alert("Please enter a link.");
      return;
    }

    let socialmedialist = {
      socialmediaid: MediaDetails?._id,
      socialmedianame: MediaDetails?.medianame,
      medialink: SocialMediaLink
    };

    // Check for duplicate social media name
    const isDuplicate = SocialMediaLinks.some(
      (item) => item.socialmedianame === socialmedialist.socialmedianame
    );

    if (isDuplicate) {
      alert(`${socialmedialist.socialmedianame} is already added.`);
      return;
    }

    // Add the new entry to the existing array
    setSocialMediaLinks([...SocialMediaLinks, socialmedialist]);

    // Alert the user after adding the link
    alert("Social media link added successfully!");

    // Clear the selected fields after adding
    setSelectedSocialMedia("");
    setSocialMediaLink("");
  };

  //To Delete

  const RemoveArray = (indexToRemove) => {
    const confirmdelete = window.confirm("Are you sure you want to delete?");
    if (confirmdelete) {
      // Filter out the item by index
      const updatedLinks = SocialMediaLinks.filter((_, index) => index !== indexToRemove);
      setSocialMediaLinks(updatedLinks); // Update state with the filtered array
    }
  };

  return (

    // <div>

    //   <div className="customerhead p-2">
    //        <div className="d-flex justify-content-between align-items-center">
    //         <h2 className="header-c ">Management Team</h2>
    //         <div className="d-flex gap-3">
    //           <button className="admin-add-btn" onClick={handleShow3}>
    //             Add 
    //           </button>
    //         </div>

    //       </div>

    //       <div className="mb-3">
    //         <Table
    //           responsive
    //           bordered
    //           style={{ width: "-webkit-fill-available" }}
    //         >
    //           <thead>
    //             <tr>
    //               <th>Sl.No</th>
    //               <th>Image</th>
    //               <th>Title</th>
    //               <th>Facebook Link</th>
    //               <th>Twitter Link</th>
    //               <th>Googleplus Link</th>
    //               <th>Action</th>
    //             </tr>
    //           </thead>

    //           <tbody>

    //             <tr>
    //               <td></td>
    //               <td>
    //                 {/* <Image
    //                       src={`http://localhost:9000/Homebanner/${item?.BannerImage}`}
    //                       alt="pic"
    //                       style={{ width: "75px", height: "75px" }}
    //                     /> */} img
    //               </td>
    //               <td style={{ paddingTop: "10px" }}>title</td>
    //               <td style={{ paddingTop: "10px" }}>facebook link</td>
    //               <td style={{ paddingTop: "10px" }}>twitter link</td>
    //               <td style={{ paddingTop: "10px" }}>googleplus link</td>

    //               <td>
    //                 {" "}
    //                 <div
    //                   style={{
    //                     display: "flex",
    //                     gap: "20px",
    //                     justifyContent: "center",
    //                   }}
    //                 >
    //                   <div>
    //                     <BiSolidEdit
    //                       className=""
    //                       style={{ cursor: "pointer", fontSize: "20px" }}
    //                       onClick={() => handleShow4()}

    //                     />{" "}
    //                   </div>
    //                   <div>
    //                     <AiFillDelete
    //                       className="text-danger"
    //                       style={{ cursor: "pointer", fontSize: "20px" }}
    //                       onClick={() => {
    //                         handleShow5();
    //                         // setDatas(item?._id);
    //                       }}
    //                     />
    //                   </div>
    //                 </div>
    //               </td>
    //             </tr>

    //           </tbody>
    //         </Table>
    //         </div>

    //         {/* Add Package modal for Slider */}

    //         <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>

    //           <Modal.Header
    //             closeButton
    //           >
    //             <Modal.Title>Add Management Team</Modal.Title>
    //           </Modal.Header>

    //           <Modal.Body>
    //             <div className="row">
    //               <div className="do-sear mt-2">
    //                 <label>Add Image</label>
    //                 <input
    //                   type="file"
    //                   name=""
    //                   id=""
    //                   className="vi_0"
    //                 // onChange={(e) => setBannerImage(e.target.files[0])}
    //                 />
    //               </div>
    //             </div>

    //             <div className="row">
    //               <div className="do-sear mt-2">
    //                 <label>Add Title</label>
    //                 <input
    //                   type="text"
    //                   className="vi_0"
    //                   placeholder="Enter Management Title"
    //                 // onChange={(e) => setBannerText(e.target.value)}
    //                 />
    //               </div>
    //             </div>

    //             <div className="row">
    //               <div className="do-sear mt-2">
    //                 <label>Add Facebook Link</label>
    //                 <input
    //                   type="text"
    //                   className="vi_0"
    //                   placeholder="Enter Facebook Link"
    //                  // onChange={(e) => setBannerText(e.target.value)}
    //                 />
    //               </div>
    //             </div>

    //             <div className="row">
    //               <div className="do-sear mt-2">
    //                 <label>Add Twitter Link</label>
    //                 <input
    //                   type="text"
    //                   className="vi_0"
    //                   placeholder="Enter Twitter Link"
    //                  // onChange={(e) => setBannerText(e.target.value)}
    //                 />
    //               </div>
    //             </div>

    //             <div className="row">
    //               <div className="do-sear mt-2">
    //                 <label>Add Google Plus Link</label>
    //                 <input
    //                   type="text"
    //                   className="vi_0"
    //                   placeholder="Enter Google Plus Link"
    //                  // onChange={(e) => setBannerText(e.target.value)}
    //                 />
    //               </div>
    //             </div>

    //           </Modal.Body>

    //           <Modal.Footer>
    //             <div className="d-flex">
    //               <Button
    //                 className="mx-2 modal-close-btn"
    //                 variant=""
    //                 onClick={handleClose3}
    //               >
    //                 Close
    //               </Button>
    //               <Button
    //                 className="mx-2 modal-add-btn"
    //                 variant=""
    //               // onClick={AddSocialmedia}
    //               >
    //                 Add
    //               </Button>
    //             </div>
    //           </Modal.Footer>
    //         </Modal>

    //         {/* Edit Package modal for Slider */}

    //         <Modal
    //           show={show4}
    //           onHide={handleClose4}
    //           backdrop="static"
    //           keyboard={false}
    //           style={{ zIndex: "99999" }}
    //         >
    //           <Modal.Header
    //             closeButton
    //           >
    //             <Modal.Title style={{ color: "black" }}>Edit Management Team</Modal.Title>
    //           </Modal.Header>


    //           <Modal.Body>
    //             <div className="row">
    //               <div className="do-sear mt-2">
    //                 <label>Edit  Image</label>
    //                 <input
    //                   type="file"
    //                   name=""
    //                   id=""
    //                   className="vi_0"
    //                 // onChange={(e) => setBannerImage(e.target.files[0])}
    //                 />
    //               </div>
    //             </div>


    //             <div className="row">
    //               <div className="do-sear mt-2">
    //                 <label>Edit  Title</label>
    //                 <input
    //                   type="text"
    //                   className="vi_0"
    //                   placeholder="Enter Management Title"
    //                 // onChange={(e) => setBannerText(e.target.value)}
    //                 />
    //               </div>
    //             </div>

    //             <div className="row">
    //               <div className="do-sear mt-2">
    //                 <label>Edit Facebook Link</label>
    //                 <input
    //                   type="text"
    //                   className="vi_0"
    //                   placeholder="Enter Facebook Link"
    //                   // onChange={(e) => setBannerText(e.target.value)}
    //                 />
    //               </div>
    //             </div>

    //             <div className="row">
    //               <div className="do-sear mt-2">
    //                 <label>Edit Twitter Link</label>
    //                 <input
    //                   type="text"
    //                   className="vi_0"
    //                   placeholder="Enter Twitter Link"
    //                  // onChange={(e) => setBannerText(e.target.value)}
    //                 />
    //               </div>
    //             </div>

    //             <div className="row">
    //               <div className="do-sear mt-2">
    //                 <label>Edit Google Plus Link</label>
    //                 <input
    //                   type="text"
    //                   className="vi_0"
    //                   placeholder="Enter Google Plus Link"
    //                  // onChange={(e) => setBannerText(e.target.value)}
    //                 />
    //               </div>
    //             </div>


    //           </Modal.Body>

    //           <Modal.Footer>
    //             <Button
    //               variant=""
    //               className="modal-close-btn"
    //               onClick={handleClose4}
    //             >
    //               Close
    //             </Button>
    //             <Button variant="" className="modal-add-btn"
    //             // onClick={editsocial}
    //             >
    //               Update
    //             </Button>
    //           </Modal.Footer>
    //         </Modal>

    //         {/*Delete Package modal for Slider */}

    //         <Modal
    //           show={show5}
    //           onHide={handleClose5}
    //           backdrop="static"
    //           keyboard={false}
    //           style={{ zIndex: "99999" }}
    //         >

    //           <Modal.Header
    //             closeButton
    //           >
    //             <Modal.Title>Warning</Modal.Title>
    //           </Modal.Header>


    //           <Modal.Body>
    //             <div className="row">
    //               <div className="col-md-12">
    //                 <p className="fs-4" style={{ color: "red" }}>
    //                   Are you sure?
    //                   <br /> you want to delete this data?
    //                 </p>
    //               </div>
    //             </div>
    //           </Modal.Body>


    //           <Modal.Footer>
    //             <Button
    //               variant=""
    //               className="modal-close-btn"
    //               onClick={handleClose5}
    //             >
    //               Close
    //             </Button>
    //             <Button
    //               variant=""
    //               className="modal-add-btn"
    //             // onClick={Deletesocial}
    //             >
    //               Delete
    //             </Button>
    //           </Modal.Footer>
    //         </Modal>
    //   </div>
    // </div>

    <div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Management Team</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add
            </button>
          </div>

        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Team Details</th>
                <th>Media Link</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>

              {ManagementTeam?.map((item, i) => {
                return (

                  <tr>
                    <td>{i + 1}</td>

                    <td>
                      <Image
                        src={`https://theuniversityoftrinity.com/AboutManagementteam/${item?.managementteamimg}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                      <div style={{ paddingTop: "10px" }}>{item?.managementteamname}</div>
                    </td>

                    <td style={{ paddingTop: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center" }}>

                      {/* Applying map to get Social Name*/}

                      {item?.SocialMedialinks?.map((item2) => {
                        return (
                          <div className="d-flex gap-1 align-items-center mb-2">
                            <b className="me-3" style={{ width: "80px" }}>{item2?.socialmedianame}:</b> {/* Bold label for the social media name */}

                            <Button
                              variant="primary"
                              className="me-3"
                              onClick={() => window.open(item2?.medialink, "_blank")} // Open the link in a new tab
                            >
                              Link
                            </Button>

                            {/* <AiFillDelete
                              className="text-danger"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                            /> */}

                          </div>
                        )
                      })}


                    </td>

                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className=""
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow4();
                              setManagementTeamId(item);
                            }}
                          />{" "}
                        </div>

                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow5();
                              setManagementTeamId(item);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}

        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>

          <Modal.Header
            closeButton
          >
            <Modal.Title>Add Management Team</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setManagementTeamImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Ourteam Name"
                  onChange={(e) => setManagementTeamTitle(e.target.value)}
                />
              </div>
            </div>

            <div style={{ border: "2px solid ", marginTop: "10px" }}>
              <div className="row">
                <div className="do-sear mt-2">
                  <label htmlFor="sel" className="form-label pt-3">
                    Media Type
                  </label>

                  <select
                    value={SelectedSocialMedia} // Maintain the selected value in the dropdown
                    onChange={(e) => setSelectedSocialMedia(e.target.value)}
                    className="form-select"
                  >
                    <option value="">Select</option>
                    {SocialMedia?.map((item) => (
                      <option value={JSON.stringify(item)}>{item?.medianame}</option>
                    ))}
                  </select>
                </div>

              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Link</label>
                  <input
                    value={SocialMediaLink}
                    type="text"
                    className="vi_0"
                    placeholder="Enter the Link"
                    onChange={(e) => setSocialMediaLink(e.target.value)}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Button onClick={AddSocialMediaLinks}>Add</Button>
              </div>
            </div>


            <Table striped bordered hover className="mt-3">
              <thead className="">
                <tr>
                  <th>Sl No</th>
                  <th>Media Name</th>
                  <th>Media Link</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {SocialMediaLinks?.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.socialmedianame}</td>
                      <td><a href={item?.medialink} target="_blank">Link</a></td>
                      <td style={{ display: "flex", justifyContent: "center" }}>


                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => RemoveArray(index)}
                          />
                        </div></td>
                    </tr>
                  )

                })}



              </tbody>
            </Table>

          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={createManagementTeam}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}

        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title style={{ color: "black" }}>Edit Management Team</Modal.Title>
          </Modal.Header>


          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit  Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setManagementTeamImage(e.target.files[0])}
                />
              </div>
            </div>


            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit  Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Ourteam Name"
                  onChange={(e) => setManagementTeamTitle(e.target.value)}
                />
              </div>
            </div>


            <div style={{ border: "2px solid ", marginTop: "10px" }}>

              <div className="row">

                <div className="do-sear mt-2">
                  <label htmlFor="sel" className="form-label pt-3">
                    Media Type
                  </label>

                  <select
                    value={SelectedSocialMedia} // Maintain the selected value in the dropdown
                    onChange={(e) => setSelectedSocialMedia(e.target.value)}
                    className="form-select"
                  >
                    <option value="">Select</option>
                    {SocialMedia?.map((item) => (
                      <option value={JSON.stringify(item)}>{item?.medianame}</option>
                    ))}
                  </select>
                </div>

              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Link</label>
                  <input
                    value={SocialMediaLink}
                    type="text"
                    className="vi_0"
                    placeholder="Enter the Link"
                    onChange={(e) => setSocialMediaLink(e.target.value)}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Button onClick={AddSocialMediaLinks}>Edit</Button>
              </div>
            </div>

            {/* <div className="row">
              <label htmlFor="sel" className="form-label">
                Media Type
              </label>

              <select
                name="sel" id="sel" className="form-select">
                <option value="">Select</option>
                <option value="services">media 1</option>
                <option value="product">media 2</option>
                <option value="product">media 3</option>
              </select>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Link</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter the Link"
                // onChange={(e) => setBannerText(e.target.value)}
                />
              </div>
            </div> */}

            {/* <Table striped bordered hover>
              <thead className="mt-4">
                <tr>
                  <th>Sl No</th>
                  <th>Media Name</th>
                  <th>Media Link</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td></td>
                  <td>name</td>
                  <td>link</td>
                  <td style={{ display: "flex", justifyContent: "center" }}><div>
                    <BiSolidEdit
                      className=""
                      style={{ cursor: "pointer", fontSize: "20px" }}
                      onClick={() => {
                        // handleShow4();
                        // setOurteamId(item);
                      }}
                    />{" "}
                  </div>

                    <div>
                      <AiFillDelete
                        className="text-danger"
                        style={{ cursor: "pointer", fontSize: "20px" }}
                        onClick={() => {
                          // handleShow5();
                          // setOurteamId(item);
                        }}
                      />
                    </div></td>
                </tr>


              </tbody>
            </Table> */}

            <Table striped bordered hover className="mt-3">
              <thead className="">
                <tr>
                  <th>Sl No</th>
                  <th>Media Name</th>
                  <th>Media Link</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {SocialMediaLinks?.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.socialmedianame}</td>
                      <td><a href={item?.medialink} target="_blank">Link</a></td>
                      <td style={{ display: "flex", justifyContent: "center" }}>


                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => RemoveArray(index)}
                          />
                        </div></td>
                    </tr>
                  )

                })}



              </tbody>
            </Table>


          </Modal.Body>

          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn"
              onClick={editManagementTeam}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delete Package modal for Slider */}

        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >

          <Modal.Header
            closeButton
          >
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>


          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>


          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={deleteManagementTeam}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>

  )
}

export default AdminAboutManagementTeam