import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const AdminAboutVision = () => {

  // Ck Editor Code

  const [Desc, setDesc] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };


  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


  //Frontend Integration

  //POST

  const createOurVision = async () => {
    try {
      const config = {
        url: "/createaboutvision",
        method: "post",
        baseURL: "https://theuniversityoftrinity.com/api/about",
        headers: { "content-type": "application/json" },
        data: {
          visiondesc: Desc,
        },
      };

      let res = await axios(config);
      console.log("res",res)
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getOurVision();
        setDesc("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //GET

  const [OurVision, setOurVision] = useState([]);

  const getOurVision = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/about/getaboutvision");
      if (res.status === 201) {
        setOurVision(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

  //UPDATE
  
  const [OurVisionId, setOurVisionId] = useState({})

  const editOurVision = async()=>{

    try {
    const config = {
      url: "/updateaboutvision/"+OurVisionId?._id,
      method: "put",
      baseURL: "https://theuniversityoftrinity.com/api/about",
      headers: { "content-type": "application/json" },
      data: {
        visiondesc: Desc,
      },
    };
    let res = await axios(config);
    if (res.status === 201) {
      alert(res.data.sucess);
      handleClose4();
      getOurVision();
      setDesc("");
    }
  }
   catch (error) {
    alert(error.response.data.error);
  }
  }

  //DELETE

  const deleteOurVision = async () => {
    try {
      let res = await axios.delete(`https://theuniversityoftrinity.com/api/about/deleteaboutvision/${OurVisionId?._id}`);
      if (res.status === 201) {
        alert(res.data.status);
        getOurVision();
        handleClose5()
      }
    } catch (error) {
      alert(error.response.data.error)
    }
  };

  useEffect(() => {
    getOurVision();
  }, []);


  return (
    <div>

    <div className="customerhead p-2">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="header-c ">Vision</h2>
              <div className="d-flex gap-3">
                <button className="admin-add-btn" onClick={handleShow3}>
                  Add 
                </button>
              </div>
            </div>
    
            <div className="mb-3">
              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
    
                  <tr>
                    <th>Sl.No</th>             
                     <th>Description</th>
                    <th>Action</th>
                  </tr>
    
                </thead>
    
                <tbody>
                 
                {OurVision?.map((item, i) => {
                    return (
                 
                      <tr>
                        <td>{i+1}</td>
                        <td style={{ paddingTop: "10px" }}>{parse(item?.visiondesc)}</td>

                        {/* <td style={{ paddingTop: "10px" }}>
                          {item?.visiondesc ? parse(String(item.visiondesc)) : "No description available"}
                        </td> */}

                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className=""
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  
                                }}
                                onClick={() =>{
                                  handleShow4()
                                 setOurVisionId(item)
                                }                                  
                                }
                              />{" "}
                            </div>
                            
                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",                                
                                }}
                                onClick={() => {
                                  handleShow5();    
                                  setOurVisionId(item)
                                }}
                              />

                            </div>
                          </div>
                        </td>
                      </tr>
    
                     );
                 })}
     
                </tbody>
              </Table>
            </div>
    
            {/* Add Package modal for Slider */}
    
            <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
              <Modal.Header closeButton>
                <Modal.Title>Add Vision</Modal.Title>
              </Modal.Header>
              <Modal.Body>
               
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Description</label>               
                    <CKEditor editor={ClassicEditor} onChange={handleChange} />
                  </div>
                </div>
    
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <Button
                    className="mx-2 modal-close-btn"
                    variant=""
                    onClick={handleClose3}
                  >
                    Close
                  </Button>
                  <Button
                    className="mx-2 modal-add-btn"
                    variant=""
                     onClick={createOurVision}
                  >
                    Add
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
    
            {/* Edit Package modal for Slider */}
    
            <Modal
              show={show4}
              onHide={handleClose4}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  Edit Vision
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
            
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Description</label>
                   
                    <CKEditor editor={ClassicEditor} onChange={handleChange} />
                  </div>
                </div>
    
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose4}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={editOurVision}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
    
            {/*Delete Package modal for Slider */}
    
            <Modal
              show={show5}
              onHide={handleClose5}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-4" style={{ color: "red" }}>
                      Are you sure?
                      <br /> you want to delete this data?
                    </p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose5}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={deleteOurVision}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
    
     </div>

  )
}

export default AdminAboutVision