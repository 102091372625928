import React, { useEffect, useState } from 'react'
import "../Styles/Footer.css"
import { Container } from "react-bootstrap";
import { IoLocationSharp } from "react-icons/io5";

import { FaLocationDot } from "react-icons/fa6";
import { MdCall } from "react-icons/md";
import { MdEmail } from "react-icons/md";

import { FaLongArrowAltRight } from "react-icons/fa";

import axios from 'axios';

import parse from 'html-react-parser';

import { FaFacebook } from "react-icons/fa6";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FaSquareInstagram } from "react-icons/fa6";

  const Footer = () => {

  //GET (Contact Details)

  const [ContactDetails, setContactDetails] = useState([]);

  console.log(ContactDetails,":ContactDetails")

  const getContactDetails = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/contact/getcontactdetails");
      if (res.status === 200) {
        setContactDetails(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

   //GET (About Description)

   const [AboutDescription , setAboutDescription] = useState([]);

   const getAboutDescription = async () => {
     try {
       let res = await axios.get("https://theuniversityoftrinity.com/api/about/getaboutdescription");
       if (res.status === 201) {
         setAboutDescription(res.data.data1);
       }
     } catch (error) {
       alert(error.response.data.error); 
     }
   };

  useEffect(() => {
    getContactDetails();
    getAboutDescription();
  }, []);


  return (
  
    <>

       <div className="container-fluid footer-main-div-gg mt-3">

          <div className="row">

              <div className="col-md-5">
                  <div className="footermain footer1">
                      <div className="footer-header">
                        <h3 className="mt-3 mb-3 text-start text-white">University of Trinity International</h3>
                      </div>

                      {AboutDescription?.map((item, i) => {
                    return (
                      <p className="text-wrap text-wrap-height text-white serviceDesc">
                        {parse(item?.aboutdesc)}
                      </p>
                    );
                    })}

                  </div>
              </div>

              <div className="col-md-3">
                <div className="footermain footer2">
                  <div className="footer-header">
                    <h3 className="mt-3 mb-3 text-start text-white">Quick Links</h3>

                    <ul className="list-unstyled">
                      
                      <div className='d-flex gap-3'>
                        <FaLongArrowAltRight className='pt-1' style={{fontSize:"25px"}}/>
                        <a href="/" className="quicklinks">
                          <li className="quicklinkmain text-left text-white text-start">HOME</li>
                        </a>
                      </div>

                      <div className='d-flex gap-3'>
                        <FaLongArrowAltRight className='pt-1' style={{fontSize:"25px"}}/>
                        <a href="/about" className="quicklinks">
                          <li className="quicklinkmain text-left text-white text-start">ABOUT</li>
                        </a>
                      </div>

                      <div className='d-flex gap-3'>
                        <FaLongArrowAltRight className='pt-1' style={{fontSize:"25px"}}/>
                        <a href="/service" className="quicklinks">
                          <li className="quicklinkmain text-left text-white text-start">SERVICE & COURSE</li>
                        </a>
                      </div>

                      <div className='d-flex gap-3'>
                        <FaLongArrowAltRight className='pt-1' style={{fontSize:"25px"}}/>
                        <a href="/gallery" className="quicklinks">
                          <li className="quicklinkmain text-left text-white text-start">GALLERY</li>
                        </a>
                      </div>

                      <div className='d-flex gap-3'>
                        <FaLongArrowAltRight className='pt-1' style={{fontSize:"25px"}}/>
                        <a href="/contact" className="quicklinks">
                          <li className="quicklinkmain text-left text-white text-start">CONTACT</li>
                        </a>
                      </div>
                      
                    </ul>

                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="footermain footer3">

                {ContactDetails?.map((item, i) => {
                        return (
                  <div className="footer-header">
                    <h3 className="mt-3 mb-3 text-start text-white">Address</h3>
                    
                    <div className='d-flex justify-content-center align-item-center'>
                      {/* <IoLocationSharp style={{fontSize:"20px",height:"20px"}}/> */}
                          {/* <FaLocationDot style={{color:"white"}}/>                    */}
                      <p className="fs-6 text-white contact-description">
                        {parse(item?.contactaddress || "")}
                      </p>
                    </div>

                    <div className='text-white text-start '>
                        <div className='d-flex'>
                        {/* <MdCall /> */}
                          <p>{item?.contactno}</p>
                        </div>
                        
                        <div className='d-flex'>
                          {/* <MdEmail /> */}
                          <p>{item?.contactemail}</p>
                        </div>
                    </div>

                  </div>

                );
                })}

                </div>
              </div>

              <div className='col-md-12'>

                  <div className='d-flex align-items-center justify-content-center gap-5 mt-2'>

                      <p className='fs-4 text-white pt-2 follow-uson'>Follow Us On</p>

                      <div className='d-flex gap-5 align-items-center'>

                        <a href='https://www.facebook.com/' target='_blank'><img src="../Assets/facebook.png" alt="" className='footer-media-icons'/></a>
                        <a href='https://x.com/?lang=en' target='_blank'><img src="../Assets/twitter.png" alt="" className='footer-media-icons'/></a>
                        <a href='https://www.instagram.com/' target='_blank'><img src="../Assets/instagram .png" alt="" className='footer-media-icons'/></a>

                      </div>

                  </div>

              </div>

          </div>

        </div> 
     
  </>
  )
}

export default Footer