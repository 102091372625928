import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AdminHomeTestimonial = () => {

  // Ck Editor Code

  // const [Desc, setDesc] = useState("");

  // const handleChange = (e, editor) => {
  //   const data = editor.getData();
  //   setDesc(data);
  // };

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


  //Frontend Integration

  const [TestimonialName, setTestimonialName] = useState("");
  const [TestimonialContent, setTestimonialContent] = useState("");

  //POST

  const createTestimonial = async () => {
    try {
      const config = {
        url: "/createtestimonial",
        method: "post",
        baseURL: "https://theuniversityoftrinity.com/api/home",
        headers: { "content-type": "application/json" },
        data: {
          testimonialtitle: TestimonialName,       
          testimonialdescription: TestimonialContent,
        },
      };

      let res = await axios(config);
      console.log("res",res)
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getTestimonial();
        setTestimonialName("");
        setTestimonialContent("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };


  //GET

  const [Testimonial, setTestimonial] = useState([]);

  const getTestimonial = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/home/gettestimonial");
      if (res.status === 201) {
        setTestimonial(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

  console.log("testimonial",Testimonial);

  //UPDATE
  
const [TestimonialId, setTestimonialId] = useState({})

  const editTestimonial = async()=>{

    try {
    const config = {
      url: "/updatetestimonial/"+TestimonialId?._id,
      method: "put",
      baseURL: "https://theuniversityoftrinity.com/api/home",
      headers: { "content-type": "application/json" },
      data: {
        testimonialtitle: TestimonialName,       
        testimonialdescription: TestimonialContent,
      },
    };
    let res = await axios(config);
    if (res.status === 201) {
      alert(res.data.sucess);
      handleClose4()
      getTestimonial();
      setTestimonialName("");
      setTestimonialContent("");
    }
  }
   catch (error) {
    alert(error.response.data.error);
  }
  }


  //DELETE

  const deleteTestimonial = async () => {
    try {
      let res = await axios.delete(`https://theuniversityoftrinity.com/api/home/deletetestimonial/${TestimonialId?._id}`);
      if (res.status === 201) {
        alert(res.data.status);
        getTestimonial();
        handleClose5()
      }
    } catch (error) {
      alert(error.response.data.error)
    }
  };

  useEffect(() => {
    getTestimonial();
  }, []);
 
  return (

  <div>

    <div className="customerhead p-2">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="header-c ">Testimonials</h2>
              <div className="d-flex gap-3">
                <button className="admin-add-btn" onClick={handleShow3}>
                  Add 
                </button>
              </div>
            </div>
    
            <div className="mb-3">
              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
    
                  <tr>
                    <th>Sl.No</th>
                    <th>Testimonial Name</th>                      
                    <th>Testimonial Content</th>                
                    <th>Action</th>
                  </tr>
    
                </thead>
    
                <tbody>
                 
                {Testimonial?.map((item, i) => {
                    return (
                 
                      <tr>
                        <td>{i+1}</td>
                        <td style={{ paddingTop: "10px" }}>{item?.testimonialtitle}</td>                      
                        <td style={{ paddingTop: "10px" }}>{item?.testimonialdescription}</td>

                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className=""
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                
                                }}
                                onClick={() =>{
                                  handleShow4()
                                 setTestimonialId(item)
                                }
                                   
                                  }
                              />{" "}
                            </div>

                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                 
                                }}
                                onClick={() => {
                                  handleShow5();
                                 setTestimonialId(item)
    
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
    
                    );
                  })}
                

                </tbody>
              </Table>
            </div>
    
            {/* Add Package modal for Slider */}
    
            <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
              <Modal.Header closeButton>
                <Modal.Title>Add Testimonial</Modal.Title>
              </Modal.Header>
              <Modal.Body>

               
              <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Testimonial Name</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Testimonial Name"
                      onChange={(e) => setTestimonialName(e.target.value)}
                    />
                  </div>
                </div>
               
                 
              <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Testimonial Content</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Testimonial Content"
                     onChange={(e) => setTestimonialContent(e.target.value)}
                    />
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <div className="d-flex">
                  <Button
                    className="mx-2 modal-close-btn"
                    variant=""
                    onClick={handleClose3}
                  >
                    Close
                  </Button>
                  <Button
                    className="mx-2 modal-add-btn"
                    variant=""
                   onClick={createTestimonial}
                  >
                    Add
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
    
            {/* Edit Package modal for Slider */}
    
            <Modal
              show={show4}
              onHide={handleClose4}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  Edit Testimonial
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                
    
              <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Testimonial Name</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Testimonial Name"
                      onChange={(e) => setTestimonialName(e.target.value)}
                    />
                  </div>
                </div>

              <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Testimonial Content</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Testimonial Content"
                      onChange={(e) => setTestimonialContent(e.target.value)}
                    />
                  </div>
                </div>
    
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose4}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={editTestimonial}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
    

        {/*Delete Package modal for Slider */}
    
            <Modal
              show={show5}
              onHide={handleClose5}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >

              <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-4" style={{ color: "red" }}>
                      Are you sure?
                      <br/> you want to delete this data?
                    </p>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose5}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={deleteTestimonial}
                >
                  Delete
                </Button>
              </Modal.Footer>

            </Modal>
          </div>
    
     </div>
  )
}

export default AdminHomeTestimonial