import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";

import parse from "html-react-parser";

import * as XLSX from "xlsx"; //to download the data in excel format
import moment from "moment";

const AdminContactReachusForm = () => {

     // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

 //Frontend Integration

  //GET

  const [ReachusEnquiry, setReachusEnquiry] = useState([]);

  const getReachusEnquiry = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/contact/getcontactreachusenq");
      if (res.status === 201) {
        setReachusEnquiry(res.data.data1);
        setNoChangeData(res.data.data1)
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

  //DELETE

  const [ReachusEnquiryId, setReachusEnquiryId]= useState()

  const deleteReachusEnquiry = async () => {
    try {
      let res = await axios.delete(`https://theuniversityoftrinity.com/api/contact/deletecontactreachusenq/${ReachusEnquiryId?._id}`);
      if (res.status === 200) {
        alert(res.data.status);
        getReachusEnquiry();
        handleClose5()
      }
    } catch (error) {
      alert(error.response.data.status)
    }
  };

  useEffect(() => {
    getReachusEnquiry();
  }, []);

   //PAGINATION

   const [currenpage, setCurrentpage] = useState(1);
   const recordsperpage = 10;
   const lastIndex = currenpage * recordsperpage;
   const firstIndex = lastIndex - recordsperpage;
 
   const records = ReachusEnquiry.slice(firstIndex, lastIndex);
   const npages = Math.ceil(ReachusEnquiry.length / recordsperpage);
   const numbers = [...Array(npages + 1).keys()].slice(1);
 
   function changePage(id) {
     setCurrentpage(id);
   }
 
   function prevpage() {
     if (currenpage !== firstIndex) {
       setCurrentpage(currenpage - 1);
     }
   }
 
   function nextpage() {
     if (currenpage !== lastIndex) {
       setCurrentpage(currenpage + 1);
     }
   }
 
   // SEARCH FILTER
 
   const [nochangedata, setNoChangeData] = useState([]);
   const [searchH, setSearchH] = useState("");
 
   const handleFilterH = (e) => {
     const searchTerm = e.target.value.toLowerCase();
     setSearchH(searchTerm);
 
     if (searchTerm !== "") {
       const filteredData = nochangedata.filter((user) =>
         Object.values(user).some((value) =>
         String(value).toLowerCase().includes(searchTerm)
         )
       );
       setReachusEnquiry(filteredData); 
     } else {
      setReachusEnquiry(nochangedata);  
     }
   };
   
 
   // DATE FILTER
 
   const [startDate, setstartDate] = useState(""); //setting the start date
   const [endDate, setendDate] = useState(""); //setting the end date
 
   const filterData = () => {
 
     if (!startDate) return alert("Please select a 'from' date");
     if (!endDate) return alert("Please select a 'to' date");
 
     // Convert input dates to moment objects
 
     const startDateObj = moment(startDate, "YYYY-MM-DD");
     const endDateObj = moment(endDate, "YYYY-MM-DD");
 
     // Ensure the end date is not before the start date
 
     if (endDateObj.isBefore(startDateObj)) {
       return alert("End date cannot be before the start date");
     }
 
     // Filter Add register array based on the date range
 
     const filteredData = ReachusEnquiry.filter((item) => {
     const itemDate = moment(item?.createdAt, "YYYY-MM-DD");
 
     // Check if itemDate falls between startDate and endDate
 
       console.log(
         "itemDate..",
         itemDate === startDateObj,
         itemDate === endDateObj
       );
 
       return (
         itemDate.isValid() &&
         itemDate.isSameOrAfter(startDateObj) &&
         itemDate.isSameOrBefore(endDateObj)
       );
     });
 
     console.log("Filtered Data: ", filteredData);
     
     // Update the state with the filtered data
 
     if (filteredData.length > 0) {
      setReachusEnquiry(filteredData);
     } else {
       alert("No records found within the selected date range");
       setReachusEnquiry([]); // Optionally, clear the data if no records found
     }
 
   };
 
   function clearbutton() {
     setendDate("");
     setstartDate("");
     getReachusEnquiry();
   }
 
 
   // EXPORT EXCEL (To get data in excel format)
 
   const handleExportExcel = () => {
 
   // Create a custom mapping for the column headers
 
     const customHeaders = ReachusEnquiry.map((item) => ({
       "Date / Time": moment(item.createdAt).format("MM/DD/YYYY, hh:mm A"),
       "User Name": item.name,
       "Phone Number": item.phone,
       "Email ID": item.email,
       "Location": item.address,
       "Message": item.description,
 
       // "Service Category":item.category,
       // "Product":item.subtitle,
     }));
 
     // Convert your custom data to an Excel sheet
     const worksheet = XLSX.utils.json_to_sheet(customHeaders);
 
     // Create a new workbook
     const workbook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(workbook, worksheet, "General Enquiries");
 
     // Download the Excel file
     XLSX.writeFile(workbook, "GeneralEnquiries.xlsx");
   };

  return (
    <div>

    <div className="customerhead p-2">

        <div className="d-flex gap-3 mb-2">


    {/* Search */}

    <div className="col-lg-4 d-flex justify-content-center">
    <div className="input-group">
      <span className="input-group-text" id="basic-addon1">
        <BsSearch />
      </span>
      <input
        type="text"
        className="form-control"
        placeholder="Search..."
        aria-describedby="basic-addon1"
        onChange={handleFilterH}
      />
    </div>
    </div>
          
    {/* Date Filter */}

    <div className="col-md-2 d-flex justify-content-center align-items-center">
    <div className="input-group">
      <label htmlFor="" className="m-auto">
        From: &nbsp;
      </label>
      <input
        type="date"
        className="form-control"
        aria-describedby="date-filter"
        value={startDate}
        onChange={(e) => setstartDate(e.target.value)}
      />
    </div>
    </div>

    <div className="col-md-2 d-flex justify-content-center align-items-center">
    <div className="input-group">
      <label htmlFor="" className="m-auto">
        To: &nbsp;
      </label>
      <input
        type="date"
        className="form-control"
        aria-describedby="date-filter"
        value={endDate}
        onChange={(e) => setendDate(e.target.value)}
      />
    </div>
    </div>

  <div  className="col-md-3 d-flex justify-content-center align-items-center" style={{gap:"10px"}}>
    <Button onClick={filterData} className="submit-buttonn">Submit</Button>
    <div> 
    <Button variant="danger" onClick={clearbutton}  style={{backgroundColor:"rgb(220,53,69)"}} className="export-excel">
      Clear
    </Button>
    </div>
    <Button
              style={{backgroundColor:"green"}}
              variant="success"
              //  className="success"
              className="export-excel"
              onClick={handleExportExcel}
       >
              Export Excel
      </Button>
    </div>{" "}
 
  </div>
    
            {/* Export Excel */}

            <div className="mb-3">
              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
    
                  <tr>
                    <th>Sl.No</th>
                    <th>Name</th>   
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Message</th>
                    <th>Enquiry Date & Time</th>
                    <th>Action</th>
                  </tr>
    
                </thead>
    
             <tbody>

                {records?.slice().reverse().map((item, i) => {
                 
                {/* {ReachusEnquiry?.map((item, i) => { */}
                    return (
                 
                      <tr>
                        <td>{i+1}</td>
                        <td style={{ paddingTop: "10px" }}>{item?.enqname}</td>
                        <td style={{ paddingTop: "10px" }}>{item?.enqemail}</td>
                        <td style={{ paddingTop: "10px" }}>{item?.enqphoneno}</td>
                        <td style={{ paddingTop: "10px" }}>{item?.enqphonemsg}</td>
                        {/* <td style={{ paddingTop: "10px" }}>date</td> */}
                        <td style={{ paddingTop: "10px" }}>{moment(item?.createdAt).format('LLL')}</td>
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >

                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                 
                                }}
                                onClick={() => {
                                  handleShow5();    
                                  setReachusEnquiryId(item)
                               
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
    
                     );
                  })}
            
                </tbody>
              </Table>
            </div>
    
    {/* Pagination */}

      <div>
        <nav>
          <ul className="pagination">
            <li className="not-allow">
              <span>
                <li className="next-prev">
                  <a
                    onClick={() => {
                      prevpage();
                    }}
                  >
                    &lt;
                  </a>{" "}
                </li>
              </span>
            </li>


            {numbers?.map((n, i) => {
              return (
                <li className="active-next" key={i}>
                  <a
                    href="#"
                    className="inactive"
                    onClick={() => changePage(n)}
                  >
                    {n}
                  </a>
                </li>
              );
            })}

            <li className="not-allow">
              <span>
                <li
                  className="next-prev"
                  onClick={() => {
                    nextpage();
                  }}
                >
                  &gt;{" "}
                </li>
              </span>
            </li>
            
          </ul>
        </nav>
      </div>


        {/*Delete Package modal for Slider */}
    
            <Modal
              show={show5}
              onHide={handleClose5}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >

              <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-4" style={{ color: "red" }}>
                      Are you sure?
                      <br/> you want to delete this data?
                    </p>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose5}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                   onClick={deleteReachusEnquiry}
                >
                  Delete
                </Button>
              </Modal.Footer>

            </Modal>
          </div>
    
     </div>
  )
}

export default AdminContactReachusForm