import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AdminServiceCourse = () => {

     // Ck Editor Code
  const [Desc, setDesc] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };


  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


  //Frontend Integration

  const [CourseImage, setCourseImage] = useState("");
  const [CourseName, setCourseName] = useState("");

  //POST

  const createCourse = async () => {
    try {
      const config = {
        url: "/createservicecourse",
        method: "post",
        baseURL: "https://theuniversityoftrinity.com/api/service",
        headers: { "content-type": "multipart/form-data" },
        data: {
          courseimg: CourseImage,       
          coursetitle: CourseName,
          coursedesc: Desc,
        },
      };

      let res = await axios(config);
      console.log("res",res)
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getCourse();
        setCourseImage("");
        setCourseName("");
        setDesc("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };


  //GET

  const [Course, setCourse] = useState([]);

  const getCourse = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/service/getservicecourse");
      if (res.status === 201) {
        setCourse(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

//UPDATE
  
const [CourseId, setCourseId] = useState({})

  const editCourse = async()=>{

    try {
    const config = {
      url: "/updateservicecourse/"+CourseId?._id,
      method: "put",
      baseURL: "https://theuniversityoftrinity.com/api/service",
      headers: { "content-type": "multipart/form-data" },
      data: {
        courseimg: CourseImage,       
        coursetitle: CourseName,
        coursedesc: Desc,
      },
    };
    let res = await axios(config);
    if (res.status === 201) {
      alert(res.data.sucess);
      handleClose4()
      getCourse();
      setCourseImage("");
      setCourseName("");
      setDesc("");
    }
  }
   catch (error) {
    alert(error.response.data.error);
  }
  }

  //DELETE

  const deleteCourse = async () => {
    try {
      let res = await axios.delete(`https://theuniversityoftrinity.com/api/service/deleteservicecourse/${CourseId?._id}`);
      if (res.status === 201) {
        alert(res.data.message);
        getCourse();
        handleClose5()
      }
    } catch (error) {
      alert(error.response.data.message)
    }
  };

  useEffect(() => {
    getCourse();
  }, []);
 

  return (
    
    <div>

    <div className="customerhead p-2">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="header-c ">Courses</h2>
              <div className="d-flex gap-3">
                <button className="admin-add-btn" onClick={handleShow3}>
                  Add 
                </button>
              </div>
            </div>
    
            <div className="mb-3">
              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
    
                  <tr>
                    <th>Sl.No</th>
                    <th>Course Title</th>
                    <th>Course Description</th>
                    <th>Course Image</th>
                    <th>Action</th>
                  </tr>
    
                </thead>
    
                <tbody>
                 
                {Course?.map((item, i) => {
                    return (
                 
                      <tr>
                        <td>{i+1}</td>
                       
                        {/* <td style={{ paddingTop: "10px" }}>{parse(item?.description)}</td> */}
    
                        <td style={{ paddingTop: "10px" }}>{item?.coursetitle}</td>
                        <td style={{ paddingTop: "10px" }}>{parse(item?.coursedesc)}</td>
                        <td style={{ paddingTop: "10px" }}>
                           <img src= {`https://theuniversityoftrinity.com/ServiceCourse/${item?.courseimg}`}  alt="course" style={{ width: "75px", height: "75px" }} />
                          </td>

                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className=""
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                 
                                }}
                                onClick={() =>{
                                  handleShow4()
                                setCourseId(item)
                                }
                                   
                                  }
                              />{" "}
                            </div>
                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                
                                }}
                                onClick={() => {
                                  handleShow5();
    
                                  setCourseId(item)
    
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
    
                     );
                  })}

                </tbody>
              </Table>
            </div>
    
            {/* Add Package modal for Slider */}
    
            <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
              <Modal.Header closeButton>
                <Modal.Title>Add Course</Modal.Title>
              </Modal.Header>
              <Modal.Body> 

                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Course Title</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Course Title"
                       onChange={(e) => setCourseName(e.target.value)}
                    />
                  </div>
                </div>

                
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Course Description</label>               
                    <CKEditor editor={ClassicEditor} onChange={handleChange} />
                  </div>
                </div>

                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Course Image</label>
                    <input
                      type="file"
                      name=""
                      id=""
                      className="vi_0"
                     onChange={(e) => setCourseImage(e.target.files[0])}
                    />
                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <Button
                    className="mx-2 modal-close-btn"
                    variant=""
                    onClick={handleClose3}
                  >
                    Close
                  </Button>
                  <Button
                    className="mx-2 modal-add-btn"
                    variant=""
                    onClick={createCourse}
                  >
                    Add
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
    
            {/* Edit Package modal for Slider */}
    
            <Modal
              show={show4}
              onHide={handleClose4}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  Edit Course
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
               
              <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Course Title</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter the Course Title"
                      onChange={(e) => setCourseName(e.target.value)}
                    />
                  </div>
                </div>

                
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Course Description</label>               
                    <CKEditor editor={ClassicEditor} onChange={handleChange} />
                  </div>
                </div>


                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Course Image</label>
                    <input
                      type="file"
                      name=""
                      id=""
                      className="vi_0"
                      accept="image/*"
                      onChange={(e) => setCourseImage(e.target.files[0])}
                    />
                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose4}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={editCourse}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
    
            {/*Delete Package modal for Slider */}
    
            <Modal
              show={show5}
              onHide={handleClose5}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-4" style={{ color: "red" }}>
                      Are you sure?
                      <br /> you want to delete this data?
                    </p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose5}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={deleteCourse}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
    
     </div>
    
  )
}

export default AdminServiceCourse