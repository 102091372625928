import React, { useEffect, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';
import { Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel'
import "../Styles/About.css";

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { FaFacebook } from "react-icons/fa6";
import { AiFillTwitterCircle } from "react-icons/ai";
import { TiSocialGooglePlusCircular } from "react-icons/ti";

import Aos from "aos";

import axios from 'axios';
import { Image } from 'react-bootstrap';
import parse from 'html-react-parser';

// import "aos/dist/aos.css";

gsap.registerPlugin(ScrollTrigger);

const About = () => {

  const options = {
    margin: 30,
    responsiveclassName: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 5000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  }, []);

  useGSAP(() => {
    gsap.to(".mycard1", {
      scale: 0.8,
      opacity: 0,
      scrollTrigger: {
        trigger: ".mycard1",
        start: "top 15%",
        end: "bottom 15%",
        scrub: true,
      },
    });
  });


  useGSAP(() => {
    gsap.to(".mycard2", {
      scale: 0.8,
      opacity: 0,
      scrollTrigger: {
        trigger: ".mycard2",
        start: "top 15%",
        end: "bottom 15%",
        scrub: true,

      },
    });
  });

  useGSAP(() => {
    gsap.to(".mycard3", {
      scale: 0.8,
      opacity: 0,
      scrollTrigger: {
        trigger: ".mycard3",
        start: "top 15%",
        end: "bottom 15%",
        scrub: true,
      },
    });
  });

  useGSAP(() => {
    gsap.to(".mycard4", {
      scale: 0.8,
      opacity: 0,
      scrollTrigger: {
        trigger: ".mycard4",
        start: "top 15%",
        end: "bottom 15%",
        scrub: true,
      },
    });
  });

  useGSAP(() => {
    gsap.to(".mycard5", {
      scale: 0.8,
      opacity: 0,
      scrollTrigger: {
        trigger: ".mycard5",
        start: "top 15%",
        end: "bottom 15%",
        scrub: true,
      },
    });
  });


  useGSAP(() => {
    gsap.to(".mycard6", {
      scale: 0.8,
      opacity: 0,
      scrollTrigger: {
        trigger: ".mycard6",
        start: "top 15%",
        end: "bottom 15%",
        scrub: true,
      },
    });
  });

  useGSAP(() => {
    gsap.to(".mycard7", {
      scale: 0.8,
      opacity: 0,
      scrollTrigger: {
        trigger: ".mycard7",
        start: "top 15%",
        end: "bottom 15%",
        scrub: true,
      },
    });
  });


  //GET (About Image)

  const [AboutImage, setAboutImage] = useState([]);

  const getAboutImage = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/about/getaboutimage");
      if (res.status === 201) {
        setAboutImage(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //GET (About Description)

  const [AboutDescription, setAboutDescription] = useState([]);

  const getAboutDescription = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/about/getaboutdescription");
      if (res.status === 201) {
        setAboutDescription(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //GET (Vision)

  const [OurVision, setOurVision] = useState([]);

  const getOurVision = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/about/getaboutvision");
      if (res.status === 201) {
        setOurVision(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };


  //GET (Our Team)

  const [Ourteam, setOurteam] = useState([]);

  const getOurTeam = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/about/getourteam");
      if (res.status === 201) {
        setOurteam(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  // GET (Social Media Details)

  const [SocialMedia, setSocialMedia] = useState([]);
  const getSocialMedia = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/about/getsocialmedia");
      if (res.status === 201) {
        setSocialMedia(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };


  //GET

  const [ManagementTeam, setManagementTeam] = useState([]);

  const getManagementTeam = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/about/getmanagementteam");
      if (res.status === 201) {
        setManagementTeam(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getAboutImage();
    getAboutDescription();
    getOurVision();

    getOurTeam();
    getSocialMedia();

    getManagementTeam();
  }, []);


  return (

    <div>

      <section className='about-bannerimg'
        style={{
          backgroundImage: `url("/Assets/banner.avif")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "200px",
          width: "100%"
        }}
      >

        <div className="container ">

          <div className='breadcrumbt'>

            <div>
              <h1 className='commonbanner-heading'>About</h1>
            </div>

            <div className='mbl-responsive-breadcrumbs'>
              <Breadcrumb>
                <FontAwesomeIcon icon={faHouse} style={{ fontSize: '14px', marginTop: '0px', color: "rgb(0,105,176)", padding: "5px" }} />
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active className='abtus-small-heading' style={{ color: "white" }}>About</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            
          </div>

        </div>
      </section>


      {/* About & History */}

      <div className="contactus mt-3 mb-3">

        <div className="leftdiv">
          {AboutImage?.map((item, i) => {
            return (
              <div className="toleft">
                {/* <img src="../Assets/carousel-image1.jpg" alt="" className='abtus-imgg' data-aos="zoom-in" data-aos-duration="3000"/> */}
                <Image
                  src={`https://theuniversityoftrinity.com/AboutImage/${item?.aboutimg}`}
                  alt="pic"
                  className='abtus-imgg'
                />
              </div>
            );
          })}
        </div>

        <div className="rightdiv">
          <div className="cardsection">

            {AboutDescription?.map((item, i) => {
              return (

                <div className="mycard mycard1">
                  <h5>{item?.abouttitle}</h5>
                  <br />
                  <p className='fs-6'>
                    {parse(item?.aboutdesc)}
                  </p>
                </div>

              );
            })}

            {/* <div className="mycard mycard2">
              <h5>History</h5>
              <br/>
              <p className='fs-6'>
                  IN 2002, the seed of Trinity Theological & Educational Trust was expanded Biblical approach is to reshape the existing ethics and Principles of Ecclesiastical Institutes, which can effectively cater the emerging needs of the Indian Christian individual Church Organizations, Missions, pastors Fellowships, Semi denominations and their unorganized independent Churches which are now in an awful position to face and to over come The greatest Spiritual, Ministerial and lawful Challenges! .
              </p>
            </div> */}

          </div>
        </div>
      </div>

      <p className='fs-1 text-center mt-3 mb-3'><b>Our Vision</b></p>

      <div className="contactuss mt-3 mb-3">

        <div className="rightdivv">

          <div className="cardsectionn">

            {OurVision?.map((item, i) => {
              return (

                <div className="mycardd mycard3">
                  <p className='ourvision-text fs-6 text-center'>
                    {parse(item?.visiondesc)}
                  </p>
                </div>

              );
            })}

            {/* <div className="mycardd mycard4">           
              <p className='ourvision-text fs-6 text-center'>
                  To equip, acknowledge and upgrade that the individual has gone through a period of discernment Episcopal Clergy Trainings and Theological Continuing Educations related to this call.
              </p>
            </div>

            <div className="mycardd mycard5">            
             <p className='ourvision-text fs-6 text-center'>
                To authorize that an individual is to take on the office of the Church ministry as per the WORD OF GOD and LAW OF THE LAND.
             </p>
           </div>


           <div className="mycardd mycard6">            
             <p className='ourvision-text fs-6 text-center'>
                To Arrange Legally to declare that the unofficial (uncertified) Independent Indian Christians embracing Christianity decades of years including the new comers to be added into our Indian Censes Population to be benefited by the Indian Minority birth rights as Indian Christians obligated b the present world's very great Democratic secular constitution of our dear motherland.
             </p>
           </div>

           <div className="mycardd mycard7">           
             <p className='ourvision-text fs-6 text-center'>
                 To Recognize and Affiliate the unorganized Independent/Pentecostal New Generation Churches and their Organizations, Theological Institutions, to Minister Successfully as National Diocesan Churches and That level organizations under the Christian Clergy Rites and Apostolic Traditions.
             </p>
           </div> */}

          </div>

        </div>
      </div>


      {/* Our Team */}

      <p className='text-center fs-1'><b>Our Team</b></p>

      <Container>

        <div className='row justify-content-center align-center m-auto mt-3 mb-5'>

          <OwlCarousel className="owl-theme" loop={false} margin={5} nav {...options}>

            {Ourteam?.map((item, i) => {
              return (

                <div className='item'>

                  <Card className='ourteam-entirecard'>
                    <Card.Img variant="top"
                      src={`https://theuniversityoftrinity.com/AboutOurteam/${item?.ourteamimg}`}
                      className='ourteam-image' />

                    <Card.Body>
                      <Card.Title className='text-center'>{item?.ourteamname}</Card.Title>

                      <div className='d-flex justify-content-center align-items-center'>

                        {item?.SocialMediaLinks?.map((item2) => {
                          const SocialMediaIcon = SocialMedia?.find((ele)=>ele._id === item2?.socialmediaId) 
                          return (
                          <>
                            <a href={item2?.link} target='_blank' className='socialmedia-link'>
                              <img src={`https://theuniversityoftrinity.com/SocialMedia/${SocialMediaIcon?.mediaicon}`} 
                              alt="" 
                              style={{ 
                                height: "25px", 
                                fontSize: "2px", 
                                color: "rgb(60,87,158)" }}

                              />&nbsp;&nbsp;
                            </a>&nbsp;&nbsp;
                          </>)
                        })}

                      </div>


                    </Card.Body>
                  </Card>

                </div>

              );
            })}

            {/* <div className='item'>
    
       <Card>
            <Card.Img variant="top" src="../Assets/ourteam5.jpg" className='ourteam-image'/>

            <Card.Body>
              <Card.Title className='text-center'>Disciplineship Meetings</Card.Title>

              <div className='d-flex justify-content-center align-items-center'>
                <a href='https://www.facebook.com/'><FaFacebook style={{fontSize:"25px",color:"rgb(60,87,158)"}}/></a>&nbsp;&nbsp;
                <a href='https://x.com/'><AiFillTwitterCircle style={{fontSize:"30px",color:"rgb(6,132,186)"}}/></a>&nbsp;&nbsp;
                <a href='https://myaccount.google.com/'><TiSocialGooglePlusCircular style={{fontSize:"35px",color:"rgb(200,35,40)"}}/></a>
              </div>
             
            </Card.Body>
       </Card>
    
    </div>

    <div className='item'>
    
       <Card>
            <Card.Img variant="top" src="../Assets/ourteam6.jpg" className='ourteam-image'/>

            <Card.Body>
              <Card.Title className='text-center'>Leadership Training</Card.Title>

              <div className='d-flex justify-content-center align-items-center'>
                <a href='https://www.facebook.com/'><FaFacebook style={{fontSize:"25px",color:"rgb(60,87,158)"}}/></a>&nbsp;&nbsp;
                <a href='https://x.com/'><AiFillTwitterCircle style={{fontSize:"30px",color:"rgb(6,132,186)"}}/></a>&nbsp;&nbsp;
                <a href='https://myaccount.google.com/'><TiSocialGooglePlusCircular style={{fontSize:"35px",color:"rgb(200,35,40)"}}/></a>
              </div>
             
            </Card.Body>
      </Card>
    
    </div>

    <div className='item'>

       <Card>
            <Card.Img variant="top" src="../Assets/ourteam7.jpg" className='ourteam-image'/>
            <Card.Body>
              <Card.Title className='text-center'>Church Planting</Card.Title>

              <div className='d-flex justify-content-center align-items-center'>
                  <a href='https://www.facebook.com/'><FaFacebook style={{fontSize:"25px",color:"rgb(60,87,158)"}}/></a>&nbsp;&nbsp;
                  <a href='https://x.com/'><AiFillTwitterCircle style={{fontSize:"30px",color:"rgb(6,132,186)"}}/></a>&nbsp;&nbsp;
                  <a href='https://myaccount.google.com/'><TiSocialGooglePlusCircular style={{fontSize:"35px",color:"rgb(200,35,40)"}}/></a>
              </div>
             
            </Card.Body>
        </Card>
    
    </div> */}

          </OwlCarousel>

        </div>

      </Container>

       {/* Management Team */}

      <p className='text-center fs-1'><b>Management Team</b></p>

      <Container>
        <div className='row justify-content-center align-center m-auto mt-3 mb-5'>

          <OwlCarousel className="owl-theme" loop={false} margin={5} nav {...options}>

            {ManagementTeam?.map((item, i) => {
              return (

                <div className='item'>

                  <Card>
                    {/* <Card.Img variant="top" src="../Assets/management-team1.jpg" className='management-team-image' /> */}

                    <Card.Img variant="top"
                      src={`https://theuniversityoftrinity.com/AboutManagementteam/${item?.managementteamimg}`}
                      className='ourteam-image' />

                    <Card.Body>
                      <Card.Title className='text-center'>{item?.managementteamname}</Card.Title>

                      <div className='d-flex justify-content-center align-items-center'>

                        {item?.SocialMedialinks?.map((item2) => {
                          const SocialMediaIcon = SocialMedia?.find((ele)=>ele._id === item2?.socialmediaid)
                          return (<>

                            <a href={item2?.medialink} target='_blank' className='socialmedia-link'>
                                <img src={`https://theuniversityoftrinity.com/SocialMedia/${SocialMediaIcon?.mediaicon}`} 
                                alt="" 
                                style={{ 
                                  height: "25px", 
                                  fontSize: "2px", 
                                  color: "rgb(60,87,158)" }}
                                />&nbsp;&nbsp; 
                                 </a>&nbsp;&nbsp;                           
                          </>)
                        })}

                      </div>


                    </Card.Body>
                  </Card>

                </div>

              );
            })}

            {/* <div className='item'>

              <Card>
                <Card.Img variant="top" src="../Assets/management-team2.jpg" className='management-team-image' />

                <Card.Body>
                  <Card.Title className='text-center'>Disciplineship Meetings</Card.Title>

                  <div className='d-flex justify-content-center align-items-center'>
                    <a href='https://www.facebook.com/'><FaFacebook style={{ fontSize: "25px", color: "rgb(60,87,158)" }} /></a>&nbsp;&nbsp;
                    <a href='https://x.com/'><AiFillTwitterCircle style={{ fontSize: "30px", color: "rgb(6,132,186)" }} /></a>&nbsp;&nbsp;
                    <a href='https://myaccount.google.com/'><TiSocialGooglePlusCircular style={{ fontSize: "35px", color: "rgb(200,35,40)" }} /></a>
                  </div>

                </Card.Body>
              </Card>

            </div>

            <div className='item'>

              <Card>
                <Card.Img variant="top" src="../Assets/management-team1.jpg" className='management-team-image' />

                <Card.Body>
                  <Card.Title className='text-center'>Leadership Training</Card.Title>

                  <div className='d-flex justify-content-center align-items-center'>
                    <a href='https://www.facebook.com/'><FaFacebook style={{ fontSize: "25px", color: "rgb(60,87,158)" }} /></a>&nbsp;&nbsp;
                    <a href='https://x.com/'><AiFillTwitterCircle style={{ fontSize: "30px", color: "rgb(6,132,186)" }} /></a>&nbsp;&nbsp;
                    <a href='https://myaccount.google.com/'><TiSocialGooglePlusCircular style={{ fontSize: "35px", color: "rgb(200,35,40)" }} /></a>
                  </div>

                </Card.Body>
              </Card>

            </div>

            <div className='item'>

              <Card>
                <Card.Img variant="top" src="../Assets/management-team2.jpg" className='management-team-image' />
                <Card.Body>
                  <Card.Title className='text-center'>Church Planting</Card.Title>

                  <div className='d-flex justify-content-center align-items-center'>
                    <a href='https://www.facebook.com/'><FaFacebook style={{ fontSize: "25px", color: "rgb(60,87,158)" }} /></a>&nbsp;&nbsp;
                    <a href='https://x.com/'><AiFillTwitterCircle style={{ fontSize: "30px", color: "rgb(6,132,186)" }} /></a>&nbsp;&nbsp;
                    <a href='https://myaccount.google.com/'><TiSocialGooglePlusCircular style={{ fontSize: "35px", color: "rgb(200,35,40)" }} /></a>
                  </div>

                </Card.Body>
              </Card>

            </div> */}

          </OwlCarousel>

        </div>

      </Container>

    </div>

  )
}

export default About