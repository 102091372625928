import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { IoMdCall } from "react-icons/io";
import "../Styles/Header.css"

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>

      <Navbar expand="lg" className={`navbar ${scrolled ? "scrolled" : ""}`}>

        <Container fluid className="back-color-gradient">

          <Navbar.Brand href="#">
            <img
              src="../Assets/logo.PNG"
              alt="Logo"
              className={`logoimg ${scrolled ? "logo-hidden" : ""}`}
            />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            className="navbar-toggle"
          />

          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            className="mt-5 offcanvas-custom"
          >

            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id="offcanvasNavbarLabel"
                className="music-logo-1"
              >
                Trinity University
              </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>

              <Nav className="justify-content-end flex-grow-1 pe-3 navlist">

                  <Nav.Link
                    as="span"
                    onClick={() => (window.location.href = "/")}
                    className="nav-item"
                  >
                    HOME
                  </Nav.Link>

                  <Nav.Link
                    as="span"
                    onClick={() => (window.location.href = "/about")}
                    className="nav-item"
                  >
                    ABOUT
                  </Nav.Link>

                  <Nav.Link
                    as="span"
                    onClick={() => (window.location.href = "/service")}
                    className="nav-item"
                  >
                    SERVICE & COURSE
                  </Nav.Link>

                  <Nav.Link
                    as="span"
                    onClick={() => (window.location.href = "/gallery")}
                    className="nav-item"
                  >
                    GALLERY
                  </Nav.Link>

                  <Nav.Link
                    as="span"
                    onClick={() => (window.location.href = "/contact")}
                    className="nav-item"
                  >
                    CONTACT
                  </Nav.Link>

            </Nav>
              
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;




// import React, { useEffect, useState } from "react";
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Offcanvas from 'react-bootstrap/Offcanvas'; // Import Offcanvas
// import "../Styles/Header.css"

// const Header = () => {
//   const [isBlinking, setIsBlinking] = useState(false);

//   const handleScroll = () => {
//     if (window.scrollY > 50) {
//       setIsBlinking(true);
//     } else {
//       setIsBlinking(false);
//     }
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);
    
//   return (

//     <div
//     className={`headers ${isBlinking ? "blink-header" : ""}`}
//     style={{
//       position: "sticky",
//       top: "0px",
//       zIndex: "9999999",
//       backgroundColor: "white",
//     }}
//     >

//       {["xl"].map((expand) => (
//         <Navbar
//           key={expand}
//           expand={expand}
//           style={{ padding: "0px", background:"rgb(177,151,205)" }}
//         >

//           <Container fluid style={{ padding: "5px 20px" }}>
//             <div className="d-flex gap-2 bg-logo-color">
//               <a href="/" className="tail-text">
//                 <img src="../Assets/logo.png" alt="logo" className="logoNavMain" />
//               </a>
//             </div>

//             <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

//             <Navbar.Offcanvas
//               id={`offcanvasNavbar-expand-${expand}`}
//               aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
//               placement="end"
//             >

//               <Offcanvas.Header closeButton></Offcanvas.Header>
//               <Offcanvas.Body>

//                 <Nav
//                   className="justify-content-end flex-grow-1 pe-3"
//                   style={{ alignItems: "center" }}
//                 >
//                   <Nav.Link href="/" className="tail-text-ss md-2">
//                      HOME
//                   </Nav.Link>

//                   <Nav.Link href="/about" className="tail-text-ss">
//                      ABOUT
//                   </Nav.Link>

//                   <Nav.Link href="/service" className="tail-text-ss">
//                     SERVICE & COURSE
//                   </Nav.Link>

//                   <Nav.Link href="/gallery" className="tail-text-ss">
//                      GALLERY
//                   </Nav.Link>

//                   <Nav.Link href="/contact" className="tail-text-ss">
//                     CONTACT
//                   </Nav.Link>
//                 </Nav>

//               </Offcanvas.Body>
//             </Navbar.Offcanvas>

//           </Container>

//         </Navbar>

//       ))}
//     </div>
//   );
// }

// export default Header;