import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import '../Styles/Service.css'
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { faHouse } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Row, Col } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel'

import Aos from "aos";
import axios from 'axios';
import parse from 'html-react-parser'
import { useLocation } from 'react-router-dom';

const Service = () => {
  const location = useLocation();
  const { id } = location.state || {};
  console.log("id", id);
  const courseRef = useRef(null);
  useLayoutEffect(() => {
    if (id && courseRef.current) {
      // Adding a timeout to ensure the DOM is fully rendered before the scroll happens
      setTimeout(() => {
        courseRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  }, [id]);
  

  const options = {

    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {

      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      }

    },

  };

  useEffect(() => {
    Aos.init();
    // window.scroll(0, 0);
  }, []);


  //GET (Service)

  const [Service, setService] = useState([]);

  const getService = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/service/getservice");
      if (res.status === 201) {
        setService(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getService();
  }, []);


  //GET (Course)

  const [Course, setCourse] = useState([]);

  const getCourse = async () => {
    try {
      let res = await axios.get("https://theuniversityoftrinity.com/api/service/getservicecourse");
      if (res.status === 201) {
        setCourse(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  return (
    <div>

      <section className='about-bannerimg'
        style={{
          backgroundImage: `url("/Assets/banner.avif")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "200px",
          width: "100%"
        }}
      >

        <div className="container ">

          <div className='breadcrumbt'>
            <div>
              <h1 className='commonbanner-heading'>Service</h1>
            </div>
            <div className='mbl-responsive-breadcrumbs'>
              <Breadcrumb>
                <FontAwesomeIcon icon={faHouse} style={{ fontSize: '14px', marginTop: '0px', color: "rgb(0,105,176)", padding: "5px" }} />
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active className='abtus-small-heading' style={{ color: "white" }}>Service</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>

        </div>
      </section>


      <Container>
        <h1 className="center-text mt-5 mb-3">Our Services:</h1>

        <div className='row mt-3 mb-3'>

          <div className='col-md-12 mb-4' data-aos="zoom-in" data-aos-duration="3000">

            {Service?.map((item, i) => {
              return (
                <div className='main-heading d-flex gap-5 '>

                    <div className="card text-center" style={{ width: '100%' }}>

                        <div className="card-body">
                          <h4 className='headingg'>{item?.servicename}</h4>
                          <p className="card-text">{parse(item?.servicedescription || "")}
                          </p>
                        </div>

                    </div>

                </div>

              );
            })}

          </div>
        </div>

      </Container>


      <Container ref={courseRef} title='avl-course'>
        <p className='text-center fs-1'><b>Our Available Courses</b></p>

        <div className='row justify-content-center align-center m-auto mt-3 mb-5'>

          <OwlCarousel className="owl-theme" loop margin={5} nav {...options}>

            {Course?.map((item, i) => {
              return (

                <div className='item'>

                  <Card className="text-center entire-card">
                    <Card.Header className="custom-card-header">
                      <b>{item?.coursetitle}</b>
                    </Card.Header>
                    <Card.Body className='couse-cardbody'>
                      <Card.Text>

                        <p className='text-start'>{parse(item?.coursedesc)}</p>

                      </Card.Text>
                    </Card.Body>
                  </Card>

                </div>

              );
            })}

            {/* <div className='item'>
    
                <Card className="text-center entire-card">
                  <Card.Header className="custom-card-header">
                    <b>DEGREE COURSES</b>
                  </Card.Header>
                  <Card.Body  className='couse-cardbody'>
                    <Card.Text>
                      <p className='text-start'>Bachelor of Theology(B.Th.)</p>
                      <p className='text-start'>Bachelor of Divinity(B.D)</p>
                      <p className='text-start'>Bachelor of Biblical Studies(BBS)</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
    
    </div>

    <div className='item'>
    
                <Card className="text-center entire-card">
                  <Card.Header className="custom-card-header">
                    <b>POST GRADUATE COURSES</b>
                  </Card.Header>

                  <Card.Body  className='couse-cardbody'>
                    <Card.Text>
                      <p className='text-start'>Master of Divinity(M.Div)</p>
                      <p className='text-start'>Master of Theology (New Testment) M.Th (NT)</p>
                      <p className='text-start'>Master of Theology (Old Testment) M.Th (OT)</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
    
    </div>

    <div className='item'>

              <Card className="text-center entire-card">
                  <Card.Header className="custom-card-header">
                    <b>DOCTORAL RESEARCH and STUDIES</b>
                  </Card.Header>

                  <Card.Body  className='couse-cardbody'>
                    <Card.Text>
                      <p className='text-start'>Doctor of Philosophy Ph.D.(Phil)</p>
                      <p className='text-start'>Doctor of Divinity(D.D)</p>
                      <p className='text-start'>Doctor of Divinity (Honorary for the eligible Candidates)</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
    
     </div> */}

          </OwlCarousel>

        </div>

      </Container>

    </div>

  )
}

export default Service